/**
 * Source: https://sgm.codebit.com.br/manutencao/46402
 * @param page {HTMLElement | Node}
 */
export function updatePageVersion5(page) {
    const summaries = page.querySelectorAll('editor-element[type="summary"]');
    for (let summary of summaries) {
        const original = summary.cloneNode(true);
        const infoDescription = summary.querySelector('.info-description');
        const pageNumber = summary.querySelector('.page-number');
        const fragment = document.createDocumentFragment();

        const descriptionFragment = document.createDocumentFragment();
        for (let child of infoDescription.childNodes) {
            descriptionFragment.appendChild(child);
        }

        const pageNumberFragment = document.createDocumentFragment();
        for (let child of pageNumber.childNodes) {
            pageNumberFragment.appendChild(child);
        }

        fragment.appendChild(descriptionFragment);
        fragment.appendChild(document.createTextNode(' :::: '));
        fragment.appendChild(pageNumberFragment);
        fragment.appendChild(document.createElement('br'));
        summary.replaceWith(fragment);

        console.info('Summary replaced by text version.', original);
    }
}

/**
 * Source: https://sgm.codebit.com.br/manutencao/45114
 * @param page {HTMLElement | Node}
 */
export function updatePageVersion1(page) {
    /**
     * @param node {Node}
     */
    function recursively(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            const bulletCount = node.textContent.split('∙').length - 1;
            // const spaceCount = node.textContent.split(' ').length - 1;
            const startsWithBullet = node.textContent.trim().startsWith('∙ ');
            if (!startsWithBullet || (startsWithBullet && bulletCount > 1)) {
                // console.debug(`Replacing bullets to spaces: ${node.textContent}`);
                node.textContent = node.textContent.replaceAll('∙', ' ');
            }
        } else {
            for (let child of node.childNodes) {
                recursively(child);
            }
        }
    }
    recursively(page);
}

import {
    createBrowserRouter,
    RouterProvider,
    useRouteError,
} from 'react-router-dom';
import React from 'react';
import RouteErrorPage from '../components/RouteErrorPage';
import Dashboard from '../dashboard/Dashboard';
import UserManagement from '../user-management/UserManagement';
import UserForm from '../user-management/UserForm';
import { EditorDocument } from '../edit-document/EditDocument';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { removeAppLoading } from '../util/AppLoading';

const adminRoutes = [
    {
        // I18N
        path: '/gestao-usuarios',
        element: <UserManagement />,
        errorElement: <HandleError />,
    },
    {
        // I18N
        path: '/gestao-usuarios/novo',
        element: <UserForm />,
        errorElement: <HandleError />,
    },
    {
        // I18N
        path: '/gestao-usuarios/editar/:id',
        element: <UserForm />,
        errorElement: <HandleError />,
    },
];

const routes = [
    {
        path: '/',
        element: <Dashboard />,
        errorElement: <HandleError />,
    },
    {
        // I18N
        path: '/dicionario',
        element: <Dashboard dictionary={true} />,
        errorElement: <HandleError />,
    },
    {
        // I18N
        path: '/novo-documento',
        element: <Dashboard newDocument={true} />,
        errorElement: <HandleError />,
    },
    {
        // I18N
        path: '/novo-documento/importar-pdf',
        element: <Dashboard importPdf={true} />,
        errorElement: <HandleError />,
    },
    {
        // I18N
        path: '/documentos/:id/:name',
        element: <EditorDocument />,
        errorElement: <HandleError />,
    },
    ...adminRoutes,
];

const router = createBrowserRouter(routes);

function HandleError() {
    return <RouteErrorPage error={useRouteError()} />;
}

function LoggedRouter() {
    const { user } = React.useContext(EnvironmentContext);

    if (!user) {
        // just after login, not ready to show screens yet
        return null;
    }

    removeAppLoading();

    return <RouterProvider router={router} />;
}

export default LoggedRouter;

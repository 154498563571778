import {
    BasicChar,
    getBrailleFromChar,
    Special,
    SymbolChar,
    toUnicode,
} from './CharMap';
import { nbspToSpace } from '../../util/NbspToSpace';
import {
    isBrNumber,
    isLatinText,
    isLetterBetweenAtoJLatin,
    isUpperCase,
    upperCharCount,
} from '../../util/TextUtil';
import {
    breakBrailleWordToSyllables,
    BreakType,
} from '../../util/BreakBrailleWordToSyllables';
import { diff_match_patch as DiffMatchPatch } from 'diff-match-patch';
import {
    applyHighlight,
    extractEditorElementAngle,
    extractEditorElementLineSegment,
    extractEditorElementNthRoot,
    parseHighlightSpecialCases,
    prepareEditorElementImageText,
    prepareMathContext,
} from '../txt/HtmlToBrailleFacil';
import { replaceBracketsAndParentheses } from '../txt/BracketsAndParentheses';

import { replaceDelimitersComputerRelated } from '../txt/ComputerRelatedContext';
import { removeEmptyHighlight } from '../txt/Highlight';
import {
    extractTextFromFragment,
    FormattingType,
    getClosestElementRepresentationGenericSpace,
    isContext,
    isContextComputerRelated,
    isContextMath,
    isEditorElementAngle,
    isEditorElementImageLayout,
    isEditorElementLineSegment,
    isEditorElementNthRoot,
    isInsideEditorElementParagraphBreak,
    isInsideFormattingBold,
    isInsideFormattingItalic,
    isInsideFormattingUnderline,
} from '../../edit-document/editor-mods/modules/core/EditorUtil';
import {
    removeInvisibleSpacesFromText,
    removeNonPrintableChars,
} from '../../edit-document/editor-mods/modules/core/ShowNonPrintableChars';
import {
    getAlignmentMap,
    removeFormatting,
} from '../../edit-document/editor-mods/modules/core/Formatting';
import { AlignmentTypes } from '../../edit-document/editor-mods/modules/core/TextAlignment';
import { getPageParagraphs } from '../../edit-document/editor-mods/modules/core/PageManipulation';
import { ConversionFlags } from './ConversionFlags';

/**
 * @typedef {Object} CharMap
 * @property {boolean} contextMath
 * @property {boolean} contextComputerRelated
 * @property {boolean} contextCatalog
 * @property {boolean} sup
 * @property {boolean} sub
 */

/**
 * @typedef {Object} WordMap
 * @property {string} word
 * @property {CharMap[]} charMap
 * @property {boolean} hasContextMath
 * @property {boolean} hasContextComputerRelated
 * @property {boolean} hasContextCatalog
 * @property {boolean} hasSup
 * @property {boolean} hasSub
 * @property {boolean} hasRecoil
 */

/**
 * @typedef {Object} ConvertedBraille
 * @property {string} brailleParagraph
 * @property {Object<number, WordMap>} wordMap
 */

/**
 * @param startStr {string | undefined}
 * @param endStr {string | undefined}
 * @return {RegExp}
 */
export function getRegexpComputerRelatedDetectUrl(
    startStr = '<',
    endStr = '>',
) {
    const regexp = `(${startStr})?(((?:\\w+:\`?\\/\`?\\/)|(?:[a-z0-9-_.'][^@\\s]{0,64}@))?([a-z0-9-]+(?:\\.[a-z0-9-]+)*(?:\\.[a-z]{2,}))(\\:\\d+)?(\`?\\/[^\\s]*?(?=(?:${endStr}|\\s|$)))?)(${endStr})?`;
    return new RegExp(regexp, 'gi');
}

/**
 * @param node { HTMLElement | ChildNode }
 */
function isHighlight(node) {
    return (
        isInsideFormattingBold(node) ||
        isInsideFormattingItalic(node) ||
        isInsideFormattingUnderline(node)
    );
}

/**
 * @param nodes {HTMLElement[]}
 */
function mergeHighlightTags(nodes) {
    let startNode = null;
    let mergeList = [];

    function merge() {
        for (const mergeNode of mergeList) {
            startNode.appendChild(mergeNode);
            nodes.splice(nodes.indexOf(mergeNode), 1);
        }
    }

    for (let node of nodes) {
        if (isHighlight(node)) {
            if (!startNode) {
                startNode = node;
            } else {
                mergeList.push(node);
            }
        } else {
            if (startNode) {
                if (
                    getClosestElementRepresentationGenericSpace(node) ||
                    isInsideEditorElementParagraphBreak(node) ||
                    nbspToSpace(
                        removeInvisibleSpacesFromText(node.textContent),
                    ) === ' '
                ) {
                    mergeList.push(node);
                } else {
                    // found the end
                    merge();
                    if (mergeList.length) {
                        // structure change restart recursively
                        mergeHighlightTags(nodes);
                        return;
                    } else {
                        startNode = null;
                    }
                }
            }
        }
    }
    merge();
}

/**
 * Use grouping in numbers bigger than 9999 (extended rules)
 *
 * @param txt {string}
 * @returns {string}
 */
export function addGroupInNumbersBiggerThan4Digits(txt) {
    return txt.replace(/([\d.,]{5,})/g, (match, number) => {
        // check if is a date with dots instead slash (#60170)
        if (number.match(/\d+\.[01]?\d\.[12]\d{3}/gm)) {
            return number;
        }
        number = number.replaceAll('.', '');
        const chunks = number.split(',');
        const intChunk = chunks[0].padStart(2, '0');
        if (intChunk.length < 5) return match;
        const decimalChunk = chunks[1] != null;
        const group = intChunk.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return group + (decimalChunk ? ',' + chunks[1] : '');
    });
}

function reverseProcessOrdinalNumbers(word) {
    return word.replace(/#([,;:ÿ?!="*}.]+)([ºª])/g, (match, g1, g2) => {
        g1 = g1
            .replaceAll(',', '1')
            .replaceAll(';', '2')
            .replaceAll(':', '3')
            .replaceAll('ÿ', '4')
            .replaceAll('?', '5')
            .replaceAll('!', '6')
            .replaceAll('=', '7')
            .replaceAll('"', '8')
            .replaceAll('*', '9')
            .replaceAll('}', '0');
        return g1 + g2;
    });
}

/**
 * @param word {string}
 * @returns {string}
 */
function processOrdinalNumbers(word) {
    // exception: ordinal numbers should be demoted in cells (#43715)
    return word.replace(/([\d\\.,]+)([ºª])/g, (match, g1, g2) => {
        g1 = g1
            .replaceAll('1', ',')
            .replaceAll('2', ';')
            .replaceAll('3', ':')
            .replaceAll('4', 'ÿ')
            .replaceAll('5', '?')
            .replaceAll('5', '?')
            .replaceAll('6', '!')
            .replaceAll('7', '=')
            .replaceAll('8', '"')
            .replaceAll('9', '*')
            .replaceAll('0', '}');
        return '#' + g1 + g2;
    });
}

/**
 * The text can be modified on some processing and the size may change.
 * This method adjust the formatting map comparing original text with modified.
 *
 * @param textBefore {string}
 * @param textAfter {string}
 * @param formattingData {Object<FormattingType, number[]>}
 */
function adjustFormatting(textBefore, textAfter, formattingData) {
    const dmp = new DiffMatchPatch();
    const diffs = dmp.diff_main(textBefore, textAfter);
    let idx = 0;
    /**
     * @param changeIdx {number}
     * @param add {boolean}
     * @param length {number}
     */
    function textModified(changeIdx, length, add) {
        for (let formattingType in formattingData) {
            const positions = formattingData[formattingType];
            if (add) {
                const idx = positions.indexOf(changeIdx);
                // send existing to right;
                for (let i = 0; i < positions.length; i++) {
                    // move to right
                    if (positions[i] >= changeIdx) {
                        positions[i] += length;
                    }
                }
                if (idx !== -1) {
                    for (let i = 0; i < length; i++) {
                        positions.splice(idx + i, 0, changeIdx + i); // fill newer positions
                    }
                }
            } else {
                for (let i = 0; i < length; i++) {
                    const removeIdx = positions.indexOf(changeIdx + i);
                    if (removeIdx !== -1) positions.splice(removeIdx, 1); // remove all involved positions
                }
                for (let i = 0; i < positions.length; i++) {
                    // move to left
                    if (positions[i] > changeIdx) {
                        positions[i] -= length;
                    }
                }
            }
        }
    }
    for (let i = 0; i < diffs.length; i++) {
        const [op, txt] = diffs[i];
        if (idx < 0) idx = 0;
        if (op === 0) {
            idx += txt.length;
        } else if (op === 1) {
            // text is added in the index
            textModified(idx, txt.length, true);
            idx += txt.length;
        } else if (op === -1) {
            // text is removed in the index
            if (diffs.length > i + 1) {
                // check if it has one more operation
                const [nextOp, nextTxt] = diffs[i + 1];
                if (nextOp === 1) {
                    // this is a replacement, add before remove to preserve formatting in area
                    textModified(idx, nextTxt.length, true);
                    idx += nextTxt.length;
                    i++; // the next operation is already executed, so skip
                }
            }
            textModified(idx, txt.length, false);
        }
    }
}

/**
 * @param chunks {number[][]}
 * @param txt {string}
 * @param formattingData {Object<FormattingType, Set<number>>}
 * @param processFn {(text: string) => string}
 * @returns {string}
 */
function processChunks(chunks, txt, formattingData, processFn) {
    if (!chunks.length) return txt;
    let changes = [];
    for (let [start, end] of chunks) {
        const sourceText = txt.substring(start, end);
        changes.push({
            start,
            end,
            processedTxt: processFn(sourceText),
        });
    }
    let reconstructedText;
    if (!changes.length) {
        reconstructedText = processFn(txt);
    } else {
        reconstructedText = txt;
        for (let [idx, { start, end, processedTxt }] of changes.entries()) {
            let before = reconstructedText.substring(0, start);
            let after = reconstructedText.substring(end);
            reconstructedText = before + processedTxt + after;
            const diff = processedTxt.length - (end - start);
            if (diff) {
                for (let i = idx + 1; i < changes.length; i++) {
                    let change = changes[i];
                    change.start += diff;
                    change.end += diff;
                }
            }
        }
    }
    adjustFormatting(txt, reconstructedText, formattingData);
    return reconstructedText;
}

/**
 * @param txt {string}
 * @param formattingData {Object<FormattingType, Set<number>>}
 * @param formattingToSkip {FormattingType[] | string[] | Set<string> | Set<FormattingType>}
 * @param processFn {(text: string) => string}
 * @returns {string}
 */
function processChunksTextWithoutFormatting(
    txt,
    formattingData,
    formattingToSkip,
    processFn,
) {
    if (!txt.length) return txt;
    if (!(formattingToSkip instanceof Set))
        formattingToSkip = new Set(formattingToSkip);
    let ignoreSet = [];
    for (let formatting of formattingToSkip) {
        const positions = formattingData[formatting];
        if (positions) ignoreSet = ignoreSet.concat(positions);
    }
    ignoreSet = new Set(ignoreSet); // remove duplicated
    let chunks = [];
    let startIdx = null;
    for (let i = 0; i < txt.length; i++) {
        if (!ignoreSet.has(i)) {
            if (startIdx == null) startIdx = i;
        } else {
            if (startIdx != null) {
                chunks.push([startIdx, i]);
                startIdx = null;
            }
        }
    }
    if (startIdx != null) chunks.push([startIdx, txt.length]);
    return processChunks(chunks, txt, formattingData, processFn);
}

/**
 * @param txt {string}
 * @param formattingData {Object<FormattingType, Set<number>>}
 * @param processFn {(text: string) => string}
 * @returns {string}
 */
function processAllText(txt, formattingData, processFn) {
    return processChunks([[0, txt.length]], txt, formattingData, processFn);
}

/**
 * @param txt {string}
 * @param formattingData {Object<FormattingType, Set<number>>}
 * @param formattingToProcess {FormattingType[] | string[] | Set<string> | Set<FormattingType>}
 * @param processFn {(text: string) => string}
 * @returns {string}
 */
function processChunksTextWithFormatting(
    txt,
    formattingData,
    formattingToProcess,
    processFn,
) {
    if (!txt.length) return txt;
    if (!(formattingToProcess instanceof Set))
        formattingToProcess = new Set(formattingToProcess);
    let formattingSet = [];
    for (let formatting of formattingToProcess) {
        const positions = formattingData[formatting];
        if (positions) formattingSet = formattingSet.concat(positions);
    }
    formattingSet = new Set(formattingSet); // remove duplicated
    let chunks = [];
    let startIdx = null;
    let lastIdx = null;
    for (let i of formattingSet) {
        if (startIdx != null && i !== lastIdx) {
            chunks.push([startIdx, lastIdx]);
            startIdx = null;
        }
        if (startIdx == null) startIdx = i;
        lastIdx = i + 1;
    }
    if (startIdx != null) chunks.push([startIdx, lastIdx]);
    return processChunks(chunks, txt, formattingData, processFn);
}

/**
 * @param paragraph {HTMLElement[] | Node[]}
 * @param brailleDocument {BrailleDocument}
 */
function translateParagraphElement(paragraph, brailleDocument) {
    const fragment = document.createDocumentFragment();
    for (let element of paragraph) {
        if (isContext(element)) {
            let items = [...element.childNodes];
            translateParagraphElement(items, brailleDocument);
            element.innerHTML = '';
            for (let item of items) {
                element.appendChild(item);
            }
            fragment.appendChild(element);
        } else if (isEditorElementImageLayout(element)) {
            const legend = element.querySelector('.info-legend');
            const description = element.querySelector('.info-description');
            const pageNumber = element.querySelector('.page-number');
            fragment.appendChild(document.createTextNode('_y'));
            for (let element of [...pageNumber.childNodes])
                fragment.appendChild(element);
            fragment.appendChild(document.createTextNode(': '));
            for (let element of [...legend.childNodes])
                fragment.appendChild(element);
            fragment.appendChild(document.createTextNode(' _`['));
            for (let element of [...description.childNodes])
                fragment.appendChild(element);
            fragment.appendChild(document.createTextNode('_`]'));
        } else if (isEditorElementNthRoot(element)) {
            const txt = extractEditorElementNthRoot(element, brailleDocument);
            fragment.appendChild(document.createTextNode(txt));
        } else if (isEditorElementLineSegment(element)) {
            const txt = extractEditorElementLineSegment(
                element,
                brailleDocument,
            );
            fragment.appendChild(document.createTextNode(txt));
        } else if (isEditorElementAngle(element)) {
            const txt = extractEditorElementAngle(element, brailleDocument);
            fragment.appendChild(document.createTextNode(txt));
        } else {
            fragment.appendChild(element);
        }
    }
    paragraph.splice(0, paragraph.length);
    paragraph.push(...fragment.childNodes);
}

/**
 * @param txt {string}
 * @return {string}
 */
function prepareEditorElementImage(txt) {
    txt = txt.replace(
        /_y([^: ]*):\s+([^_`[]*)_`\[([^_`\]]*)_`]/gm,
        (match, g1, g2, g3) => {
            // this is a fast solution, for now I can see no collateral effect, in the ideal world is creating a
            // new context and update the formatting at char position, but for now I cannot spent project time on it
            // '╤' is same as '"' or '×' in math context, but doesn't need context
            return `_y${prepareEditorElementImageText(g1, '╤')}: ${prepareEditorElementImageText(g2, '╤')} _\`[${prepareEditorElementImageText(g3, '╤')}_\`]`;
        },
    );
    return txt;
}

/**
 * @param text {string}
 * @param formatting {Object<FormattingType, number[]>}
 */
function removeFormattingFromSpaces(text, formatting) {
    for (let formattingType in formatting) {
        const array = formatting[formattingType];
        let blocks = [];
        let lastBlock = null;
        let lastIdx = null;
        for (let idx of array) {
            if (idx - 1 !== lastIdx) {
                lastBlock = [];
                blocks.push(lastBlock);
            }
            lastIdx = idx;
            lastBlock.push(idx);
        }
        for (let block of blocks) {
            while (block.length && text[block[0]] === ' ') {
                // trim left
                array.splice(array.indexOf(block[0]), 1);
                block.shift();
            }
            while (block.length && text[block[block.length - 1]] === ' ') {
                // trim right
                array.splice(array.indexOf(block[block.length - 1]), 1);
                block.pop();
            }
        }
    }
}

/**
 * @param paragraphs {Node[][]}
 * @param brailleDocument {BrailleDocument}
 * @returns {ConvertedBraille[]}
 */
export function convertElementsToBraille(paragraphs, brailleDocument) {
    /**
     * @type ConvertedBraille[]
     */
    let result = [];

    for (
        let paragraphIdx = 0;
        paragraphIdx < paragraphs.length;
        paragraphIdx++
    ) {
        let paragraph = paragraphs[paragraphIdx];
        const original = paragraph;
        paragraph = [];
        // do not mess with original elements, it's only to render in braille
        for (let node of original) paragraph.push(node.cloneNode(true));
        translateParagraphElement(paragraph, brailleDocument);
        mergeHighlightTags(paragraph);

        let { formatting, fragment } = removeFormatting(paragraph);
        let text = extractTextFromFragment(fragment);
        removeFormattingFromSpaces(text, formatting);
        const hasRecoil =
            !!original.find((element) => isEditorElementImageLayout(element)) ||
            !!original.find((element) => isContextComputerRelated(element)) ||
            !!original.find((element) => isContextMath(element));

        let braille = '';
        let flags = [];

        /**
         * @param flag {ConversionFlags}
         * @returns boolean
         */
        function isNotFlaggedAndFlag(flag) {
            if (flags.includes(flag)) {
                return false;
            } else {
                flags.push(flag);
                return true;
            }
        }

        /**
         * @param flag {ConversionFlags}
         * @returns boolean
         */
        function isFlaggedAndRelease(flag) {
            if (flags.includes(flag)) {
                flags.splice(flags.indexOf(flag), 1);
                return true;
            } else {
                return false;
            }
        }

        /**
         * @param idx {number}
         * @param types {FormattingTypeValue | FormattingTypeValue[]}
         * @returns {boolean}
         */
        function hasFormat(idx, types) {
            if (!Array.isArray(types)) types = [types];
            for (let type of types) {
                if (formatting[type]?.includes(idx)) return true;
            }
            return false;
        }

        // extra request at #39013, comment 5
        text = processChunksTextWithoutFormatting(
            text,
            formatting,
            [
                FormattingType.CONTEXT_COMPUTER_RELATED,
                FormattingType.CONTEXT_MATH,
            ],
            (txt) => {
                txt = replaceBracketsAndParentheses(txt);

                // makes the braille fácil format compatible
                txt = txt
                    .replaceAll('`(', '╘')
                    .replaceAll('`)', '╛')
                    .replaceAll('`[', '╓')
                    .replaceAll('`]', '╖');

                return txt;
            },
        );

        text = processChunksTextWithFormatting(
            text,
            formatting,
            [FormattingType.CONTEXT_COMPUTER_RELATED],
            (txt) => {
                // when informative context, replaces the symbols < and > to informative context delimiter (#48626)
                txt = replaceDelimitersComputerRelated(
                    txt,
                    undefined,
                    undefined,
                    '╞',
                    '╡',
                );

                txt = txt.replaceAll('/', '┐');
                return txt;
            },
        );

        text = processChunksTextWithoutFormatting(
            text,
            formatting,
            [
                FormattingType.CONTEXT_COMPUTER_RELATED,
                FormattingType.CONTEXT_CATALOG,
                FormattingType.SUB,
                FormattingType.SUP,
            ],
            (txt) => {
                return addGroupInNumbersBiggerThan4Digits(txt);
            },
        );

        text = processChunksTextWithFormatting(
            text,
            formatting,
            [FormattingType.SUB, FormattingType.SUP],
            (txt) => {
                return addGroupInNumbersBiggerThan4Digits(txt);
            },
        );

        text = processChunksTextWithFormatting(
            text,
            formatting,
            [
                FormattingType.BOLD,
                FormattingType.ITALIC,
                FormattingType.UNDERLINE,
            ],
            (txt) => {
                txt = applyHighlight(txt);
                txt = parseHighlightSpecialCases(txt, {
                    bulletChar: '•',
                    hyphenChar: '—',
                });
                return txt;
            },
        );

        text = processChunksTextWithFormatting(
            text,
            formatting,
            [FormattingType.CONTEXT_MATH],
            (txt) => {
                return prepareMathContext(txt, false);
            },
        );

        text = processAllText(text, formatting, (txt) => {
            txt = processOrdinalNumbers(txt);
            txt = prepareEditorElementImage(txt);
            txt = removeEmptyHighlight(txt);
            return txt;
        });

        const wordSplit = nbspToSpace(text)
            .replace(/ *\n/g, '\n')
            .split(' ')
            .map((word) => [...word.split(/(?<=\n)/g)]);
        let words = [];

        for (let chunk of wordSplit) {
            words = [...words, ...chunk];
        }

        /**
         * @type {Object<number, WordMap>}
         */
        let wordMap = {};
        for (let [wordIdx, word] of words.entries()) {
            /**
             * @type {CharMap[]}
             */
            let charMap = [];
            isFlaggedAndRelease(ConversionFlags.UPPER_CASE);

            // separes the word of symbol, like parenthesis
            let wordChunks = word
                .split(/([-a-zA-ZÀ-ÿ]+|[^\w\s])/g)
                .filter(
                    (word) =>
                        word.trim().length >= 1 || word.indexOf('\n') !== -1,
                );

            let flagNumber = false;
            let hasContextMath = false;
            let hasContextComputerRelated = false;
            let hasContextCatalog = false;
            let hasSup = false;
            let hasSub = false;
            for (let [chunkIdx, chunk] of wordChunks.entries()) {
                // scan word chunks and convert char by char
                for (let idx = 0; idx < chunk.length; idx++) {
                    const char = chunk[idx];

                    /**
                     * find the char index in text (word split is transformed and may change the position of break line)
                     * @return {number}
                     */
                    function getCharIdx() {
                        const referencePath =
                            words.slice(0, wordIdx).join('') +
                            wordChunks.slice(0, chunkIdx).join('') +
                            chunk.substring(0, idx + 1);
                        const charOccurrences =
                            referencePath.split(char).length - 1;
                        let occurrencesFound = 0;
                        let charIdx;
                        for (let i = 0; i < text.length; i++) {
                            if (text[i] === char) occurrencesFound++;
                            if (occurrencesFound >= charOccurrences) {
                                charIdx = i;
                                break;
                            }
                        }
                        if (charIdx == null)
                            throw Error('Char index not found');
                        return charIdx;
                    }
                    const charIdx = getCharIdx();

                    const contextMath = hasFormat(
                        charIdx,
                        FormattingType.CONTEXT_MATH,
                    );
                    const contextComputerRelated = hasFormat(
                        charIdx,
                        FormattingType.CONTEXT_COMPUTER_RELATED,
                    );
                    const contextCatalog = hasFormat(
                        charIdx,
                        FormattingType.CONTEXT_CATALOG,
                    );

                    const sup = hasFormat(charIdx, FormattingType.SUP);
                    const sub = hasFormat(charIdx, FormattingType.SUB);
                    charMap.push({
                        contextMath,
                        contextComputerRelated: contextComputerRelated,
                        contextCatalog,
                        sup,
                        sub,
                    });
                    // -------------------------
                    // mark the formatting/flags
                    // -------------------------
                    contextMath &&
                        !isNotFlaggedAndFlag(ConversionFlags.CONTEXT_MATH);
                    contextComputerRelated &&
                        !isNotFlaggedAndFlag(
                            ConversionFlags.CONTEXT_COMPUTER_RELATED,
                        );
                    contextCatalog &&
                        !isNotFlaggedAndFlag(ConversionFlags.CONTEXT_CATALOG);
                    if (sup && isNotFlaggedAndFlag(ConversionFlags.SUP)) {
                        flagNumber = false;
                        braille += toUnicode(Special.SUP);
                    }
                    if (sub && isNotFlaggedAndFlag(ConversionFlags.SUB)) {
                        flagNumber = false;
                        braille += toUnicode(Special.SUB);
                    }
                    if (
                        isUpperCase(word) &&
                        upperCharCount(word) > 1 &&
                        isNotFlaggedAndFlag(ConversionFlags.UPPER_CASE)
                    ) {
                        // upper case symbol should start right before text (and not behind parenthesis, for example)
                        if (isLatinText(chunk.charAt(0))) {
                            flagNumber = false;
                            braille += toUnicode(Special.UPPER_CASE_WORD);
                        } else {
                            // remove the flag in this case to put the symbol in right place
                            isFlaggedAndRelease(ConversionFlags.UPPER_CASE);
                        }
                    }
                    // -------------------------
                    if (isBrNumber(char)) {
                        if (!flagNumber) {
                            braille += toUnicode(Special.NUMBER);
                            flagNumber = true;
                        }
                    } else {
                        const numberGrouping = char.match(/[,.]/g);
                        const numberOrdinalOrDegree = char.match(/[ºª°]/g);
                        // the chars '╘', '╛', '╤', '╓', '╖' are special to skip some complexity (context) at development
                        const numberOperator = char.match(
                            /[-+÷—×=()\\[\]{}:╘╛╤╓╖]/g,
                        );
                        const numberSymbol =
                            numberGrouping ||
                            numberOrdinalOrDegree ||
                            numberOperator;
                        if (char !== '\n') {
                            if (!flagNumber || !numberSymbol) {
                                if (
                                    !flags.includes(
                                        ConversionFlags.UPPER_CASE,
                                    ) &&
                                    isUpperCase(char)
                                ) {
                                    braille += toUnicode(Special.UPPER_CASE);
                                } else if (
                                    flagNumber &&
                                    isLetterBetweenAtoJLatin(char)
                                ) {
                                    // numbers are exception in computer related context (#48061)
                                    braille += toUnicode(
                                        contextComputerRelated
                                            ? Special.RETURNER_COMPUTER_RELATED_CONTEXT
                                            : Special.RETURNER,
                                    );
                                }
                                flagNumber = false;
                            } else {
                                if (numberOperator) {
                                    flagNumber = false;
                                }
                            }
                        }
                    }

                    /**
                     * @type {number | number[] | string}
                     */
                    let brailleChar = getBrailleFromChar(flags, char);
                    if (brailleChar == null) {
                        if (char === '\n') {
                            brailleChar = '\n';
                        } else {
                            console.warn(`No translation to char ${char}`);
                        }
                    }
                    if (brailleChar) {
                        if (brailleChar !== '\n') {
                            braille += toUnicode(brailleChar);
                        } else {
                            braille += brailleChar;
                        }
                    }

                    const nextCharIdx = charIdx + 1;

                    // ----------------------------
                    // release the formatting/flags
                    // ----------------------------
                    !hasFormat(nextCharIdx, FormattingType.CONTEXT_MATH) &&
                        isFlaggedAndRelease(ConversionFlags.CONTEXT_MATH);
                    !hasFormat(
                        nextCharIdx,
                        FormattingType.CONTEXT_COMPUTER_RELATED,
                    ) &&
                        isFlaggedAndRelease(
                            ConversionFlags.CONTEXT_COMPUTER_RELATED,
                        );
                    !hasFormat(nextCharIdx, FormattingType.CONTEXT_CATALOG) &&
                        isFlaggedAndRelease(ConversionFlags.CONTEXT_CATALOG);
                    if (
                        !hasFormat(nextCharIdx, [
                            FormattingType.BOLD,
                            FormattingType.ITALIC,
                            FormattingType.UNDERLINE,
                        ]) &&
                        isFlaggedAndRelease(ConversionFlags.HIGHLIGHT)
                    )
                        braille += toUnicode(Special.HIGHLIGHT);
                    // ----------------------------

                    hasContextMath |= contextMath;
                    hasContextComputerRelated |= contextComputerRelated;
                    hasContextCatalog |= contextCatalog;
                    hasSup |= sup;
                    hasSub |= sub;
                }
            }

            // convert special chars to "normal" form to avoid problems breaking lines (next steps)
            word = word
                .replaceAll('╘', '(')
                .replaceAll('╛', ')')
                .replaceAll('╤', '"')
                .replaceAll('╓', '[')
                .replaceAll('╖', ']')
                .replaceAll('╞', '<')
                .replaceAll('╡', '>');

            wordMap[wordIdx] = {
                word,
                charMap,
                hasContextMath,
                hasContextComputerRelated,
                hasContextCatalog,
                hasSup,
                hasSub,
                hasRecoil,
            };
            if (
                wordIdx + 1 < words.length &&
                !words[wordIdx].endsWith('\n') &&
                !words[wordIdx + 1].startsWith('\n')
            ) {
                braille += toUnicode(BasicChar[' ']);
            }
        }
        result.push({
            brailleParagraph: braille,
            wordMap,
        });
    }
    return result;
}

/**
 * @param paragraph {ConvertedBraille}
 * @param brailleDocument {BrailleDocument}
 * @returns {{breaks: {wordIdx: number, wordOffset: number|null, searchText: string, occurrences: number, useSymbol: boolean, mathBreak: boolean, operator: string|null}[], paragraphs: string[]}}
 */
export function breakLinesToFitGrid(paragraph, brailleDocument) {
    const {
        brailleCellColCount,
        hyphenation,
        hyphenationLettersMin,
        hyphenationParagraphMax,
        hyphenationSyllablesMin,
        hyphenationDistanceBetweenHyphens,
    } = brailleDocument;

    const SPACE_CHAR = toUnicode(BasicChar[' ']);
    const HYPHEN_CHAR = toUnicode(SymbolChar['-']);

    const wordSplit = paragraph.brailleParagraph
        .split(SPACE_CHAR)
        .map((word) => [...word.split(/(?<=\n)/g)]);
    let words = [];
    for (let chunk of wordSplit) {
        words = [...words, ...chunk];
    }

    let paragraphHyphenations = 0;
    let paragraphs = [];
    let newRow = '';
    /**
     * @type { {wordIdx: number, wordOffset: number|null, searchText: string, occurrences: number, useSymbol: boolean, mathBreak: boolean, operator: string|null}[] }
     */
    let breaks = [];

    /**
     * @param wordIdx {number}
     * @param offset {number | null}
     * @returns { {searchText: string | null, occurrences: number | null} }
     */
    function getOccurrence(wordIdx, offset) {
        function prepareText(text) {
            // some processing changes the text to another text, broking this algorithm
            // the search text should be prepared in this situations
            return reverseProcessOrdinalNumbers(text);
        }

        let searchText = prepareText(paragraph.wordMap[wordIdx].word);
        searchText = searchText.charAt(offset ?? 0);
        if (searchText === '') {
            return {
                searchText: null,
                occurrences: null,
            };
        }
        let occurrences = 0;
        for (let i = 0; i <= wordIdx; i++) {
            const word = prepareText(paragraph.wordMap[i].word);

            let lastIdx = -1;
            while ((lastIdx = word.indexOf(searchText, lastIdx + 1)) !== -1) {
                occurrences++;
                if (wordIdx === i && lastIdx >= offset) break;
            }
        }
        return {
            searchText,
            occurrences,
        };
    }

    for (let [wordIdx, word] of words.entries()) {
        const lastWord = wordIdx + 1 === words.length;
        const endWithParagraphBreak = word.endsWith('\n') && !lastWord;
        const wordMap = paragraph.wordMap[wordIdx];
        word = word.trim();
        if (wordMap) wordMap.word = wordMap.word.trim();
        const hasSpace = wordIdx + 1 < words.length;
        let spaceAvailableRow =
            brailleCellColCount - (word.length + newRow.length);

        function flushNewRow(checkLength = true) {
            const recoilSpaces = SPACE_CHAR + SPACE_CHAR;
            if ((newRow.length && newRow !== recoilSpaces) || !checkLength)
                paragraphs.push(newRow);
            newRow = '';
            if (wordMap.hasRecoil) {
                newRow += recoilSpaces;
            }
        }

        if (spaceAvailableRow < 0) {
            const brokenWord = breakBrailleWordToSyllables(
                word,
                wordMap,
                hyphenationDistanceBetweenHyphens,
            );

            // console.debug(brokenWord.brailleSyllables);
            // console.debug(brokenWord.wordSyllables);

            let brailleSyllables = [...brokenWord.brailleSyllables];
            let wordSyllables = [...brokenWord.wordSyllables];

            if (
                brokenWord.type !== BreakType.NORMAL ||
                (hyphenation &&
                    brailleSyllables.length >= hyphenationSyllablesMin &&
                    paragraphHyphenations < hyphenationParagraphMax &&
                    brailleSyllables.length > 1)
            ) {
                let hyphenated = false;
                let idxSyllable = 0;
                let mathOperator = null;
                let operator = null;
                let wordInteractions = 0;

                do {
                    const syllablesBeforeStart = brailleSyllables.length;
                    let hyphenatedBraille = '';
                    let hyphenatedWord = '';
                    while (brailleSyllables.length) {
                        // check if it has space to syllable
                        if (
                            brailleSyllables[0].length + 1 <=
                            brailleCellColCount -
                                (newRow.length + hyphenatedBraille.length)
                        ) {
                            hyphenated = true;
                            hyphenatedBraille += brailleSyllables.shift();
                            hyphenatedWord += wordSyllables.shift();
                            idxSyllable++;
                        } else {
                            if (
                                brailleSyllables.length ===
                                brokenWord.brailleSyllables.length
                            )
                                break;
                            if (
                                brokenWord.type === BreakType.MATH &&
                                wordMap.hasContextMath &&
                                brailleSyllables.length
                            ) {
                                const operator = hyphenatedBraille.slice(-1);
                                brailleSyllables[0] =
                                    operator + brailleSyllables[0];
                                mathOperator =
                                    brokenWord.wordSyllables[
                                        idxSyllable - 1
                                    ]?.slice(-1);
                                if (!mathOperator) {
                                    console.warn(
                                        `Unable to retrieve math operator. Details: ${JSON.stringify(
                                            {
                                                brokenWord,
                                                syllables: brailleSyllables,
                                            },
                                        )}`,
                                    );
                                }
                            }
                            break;
                        }
                    }

                    if (hyphenatedBraille.length) {
                        const hyphenCount =
                            hyphenatedWord.split('-').length - 1;
                        if (
                            brokenWord.type !== BreakType.NORMAL ||
                            !brailleSyllables.length || // no more syllables
                            hyphenatedWord.length - hyphenCount >=
                                hyphenationLettersMin
                        ) {
                            newRow += hyphenatedBraille;
                        } else {
                            brailleSyllables.splice(0, 0, hyphenatedBraille);
                            wordSyllables.splice(0, 0, hyphenatedBraille);
                            hyphenated = false;
                        }
                    }

                    // used in second+ interaction with the remains of the hyphened word
                    if (!brailleSyllables.length) {
                        // when comes here, the data fits in the row
                        break;
                    }

                    const finishedWithHyphen =
                        newRow.charAt(newRow.length - 1) === HYPHEN_CHAR &&
                        // hyphen in math is minus signal
                        brokenWord.type === BreakType.MATH;

                    let breakAtHyphen =
                        (brailleSyllables[0] === HYPHEN_CHAR ||
                            finishedWithHyphen) &&
                        brokenWord.type === BreakType.NORMAL;

                    const useSymbol =
                        (!wordMap.hasContextMath &&
                            brokenWord.type === BreakType.NORMAL) ||
                        breakAtHyphen;
                    if (
                        hyphenated &&
                        syllablesBeforeStart !== brailleSyllables.length
                    ) {
                        paragraphHyphenations++;
                        if (brokenWord.type === BreakType.URL) {
                            newRow += toUnicode(SymbolChar['~']);
                            breakAtHyphen = false;
                            mathOperator = '';
                        } else if (useSymbol && !finishedWithHyphen) {
                            newRow += HYPHEN_CHAR;
                        }
                        if (breakAtHyphen) {
                            operator = '-';
                        }
                        let wordOffset = 0;
                        for (let i = 0; i < idxSyllable; i++) {
                            wordOffset +=
                                brokenWord.wordSyllables[i]?.length ??
                                idxSyllable;
                        }
                        breaks.push({
                            wordIdx,
                            wordOffset,
                            ...getOccurrence(wordIdx, wordOffset),
                            useSymbol,
                            mathBreak: mathOperator != null,
                            operator: mathOperator ?? operator,
                        });
                    } else if (wordInteractions === 0) {
                        breaks.push({
                            wordIdx,
                            wordOffset: null,
                            ...getOccurrence(wordIdx, null),
                            useSymbol: false,
                            mathBreak: mathOperator != null,
                            operator: mathOperator ?? operator,
                        });
                    }

                    let giveAChance =
                        brailleSyllables.length &&
                        brailleSyllables[0].length <= brailleCellColCount &&
                        brokenWord.brailleSyllables.length ===
                            brailleSyllables.length &&
                        newRow.length &&
                        wordInteractions === 0;

                    flushNewRow();

                    if (!giveAChance) {
                        if (finishedWithHyphen && breakAtHyphen) {
                            newRow += HYPHEN_CHAR;
                        }

                        if (!hyphenated) {
                            while (brailleSyllables.length) {
                                const syllable = brailleSyllables.shift();
                                newRow += syllable;
                            }
                            break;
                        }

                        if (brailleSyllables.length === syllablesBeforeStart) {
                            console.warn(
                                `Cannot break large syllable/chunk: "${wordSyllables[0]}"`,
                            );
                            newRow += brailleSyllables.shift();
                            hyphenatedWord += wordSyllables.shift();
                            idxSyllable++;
                            let wordOffset = 0;
                            for (let i = 0; i < idxSyllable; i++) {
                                wordOffset +=
                                    brokenWord.wordSyllables[i]?.length ??
                                    idxSyllable;
                            }
                            breaks.push({
                                wordIdx,
                                wordOffset,
                                ...getOccurrence(wordIdx, wordOffset),
                                useSymbol: false,
                                mathBreak: mathOperator != null,
                                operator: mathOperator ?? operator,
                            });
                            flushNewRow(false);
                        }
                    }

                    wordInteractions++;
                } while (brailleSyllables.length);

                // add remaining syllables to new line
                if (endWithParagraphBreak) {
                    paragraphs.push(newRow);
                    newRow = '';
                    if (wordMap.hasRecoil) newRow += SPACE_CHAR + SPACE_CHAR;
                } else if (hasSpace && newRow.length < brailleCellColCount) {
                    newRow += SPACE_CHAR;
                }
                continue;
            } else {
                breaks.push({
                    wordIdx,
                    wordOffset: null,
                    ...getOccurrence(wordIdx, null),
                    useSymbol: false,
                    mathBreak: false,
                    operator: null,
                });
                flushNewRow();
            }
        }

        newRow += word;
        if (endWithParagraphBreak) {
            flushNewRow(false);
        } else if (hasSpace && newRow.length < brailleCellColCount) {
            newRow += SPACE_CHAR;
        }
    }

    if (newRow.length) {
        paragraphs.push(newRow);
    }
    breaks = breaks.sort((a, b) => {
        const diff = b.wordIdx - a.wordIdx;
        if (diff === 0) {
            return b.wordOffset - a.wordOffset;
        }
        return diff;
    });

    return {
        breaks,
        paragraphs,
    };
}

/**
 *
 * @param node {HTMLElement | ChildNode}
 * @returns {boolean}
 */
export function isEditorBrailleView(node) {
    return node?.tagName === 'EDITOR-BRAILLE-VIEW';
}

/**
 * @param page {HTMLElement}
 * @param brailleDocument {BrailleDocument}
 * @returns {{brailleGrid: string[], paragraphChanges: ParagraphChange[]}}
 */
export function convertPageToBraille(page, brailleDocument) {
    page = page.cloneNode(true);
    removeNonPrintableChars(page);
    const alignmentMap = getAlignmentMap(page);
    const paragraphs = convertElementsToBraille(
        getPageParagraphs(page),
        brailleDocument,
    );

    /**
     * @type {string[]}
     */
    const result = [];
    /**
     * @type {ParagraphChange[]}
     */
    let paragraphChanges = [];
    for (let [idxParagraph, paragraph] of paragraphs.entries()) {
        const { paragraphs: preparedParagraphs, breaks } = breakLinesToFitGrid(
            paragraph,
            brailleDocument,
        );

        // do the alignment in paragraph
        const alignment = alignmentMap[idxParagraph];
        if (alignment) {
            const spaceChar = toUnicode(BasicChar[' ']);
            for (let [idx, paragraph] of preparedParagraphs.entries()) {
                let colDiff =
                    brailleDocument.brailleCellColCount - paragraph.length;
                let leftSpaces;
                if (alignment === AlignmentTypes.CENTER) {
                    leftSpaces = Math.round(colDiff / 2);
                } else if (alignment === AlignmentTypes.RIGHT) {
                    leftSpaces = colDiff;
                }
                if (leftSpaces < 0) {
                    leftSpaces = 0;
                    console.warn('Cannot align, column overflow', paragraph);
                }
                preparedParagraphs[idx] =
                    spaceChar.repeat(leftSpaces) + paragraph;
            }
        }

        paragraphChanges.push({
            paragraph: idxParagraph,
            breaks,
        });

        if (!preparedParagraphs.length) {
            result.push('');
            continue;
        } else if (preparedParagraphs.length >= 1) {
            // paragraph was broken for space limitations
        }
        for (let prepared of preparedParagraphs) {
            result.push(prepared);
        }
    }

    paragraphChanges = paragraphChanges.filter((change) => {
        return change.breaks.length;
    });

    return {
        brailleGrid: result,
        paragraphChanges,
    };
}

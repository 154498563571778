import { ConversionFlags } from './ConversionFlags';

/**
 * @type {Object<string,  number|number[]>}
 */
export const NumberChar = {
    1: 0x01,
    2: 0x03,
    3: 0x09,
    4: 0x19,
    5: 0x11,
    6: 0x0b,
    7: 0x1b,
    8: 0x13,
    9: 0x0a,
    0: 0x1a,
};

/**
 * @type {Object<string,  number|number[]>}
 */
export const BasicChar = {
    ' ': 0x00,
    a: 0x01,
    b: 0x03,
    c: 0x09,
    d: 0x19,
    e: 0x11,
    f: 0x0b,
    g: 0x1b,
    h: 0x13,
    i: 0x0a,
    j: 0x1a,
    k: 0x05,
    l: 0x07,
    m: 0x0d,
    n: 0x1d,
    o: 0x15,
    ö: 0x15,
    p: 0x0f,
    q: 0x1f,
    r: 0x17,
    s: 0x0e,
    t: 0x1e,
    u: 0x25,
    v: 0x27,
    w: 0x3a,
    x: 0x2d,
    y: 0x3d,
    z: 0x35,
    ç: 0x2f,
    é: 0x3f,
    á: 0x37,
    è: 0x2e,
    ú: 0x3e,
    â: 0x21,
    ê: 0x23,
    ì: 0x29,
    ô: 0x39,
    ù: 0x31,
    à: 0x2b,
    ï: 0x3b,
    ü: 0x33,
    õ: 0x2a,
    í: 0x0c,
    ã: 0x1c,
    ó: 0x2c,
    ÿ: 0x32,
    ý: 0x20,
};

/**
 * @type {Object<string,  number|number[]>}
 */
export const SymbolChar = {
    ',': 0x02,
    '.': 0x04,
    // eslint-disable-next-line
    "'": [0x20, 0x26],
    '’': [0x20, 0x26],
    '‘': [0x20, 0x26],
    ';': 0x06,
    ':': 0x12,
    '!': 0x16,
    '?': 0x22,
    '-': 0x24,
    '"': 0x26,
    '“': 0x26,
    '”': 0x26,
    '(': [0x23, 0x04],
    ')': [0x04, 0x1c],
    '[': [0x37, 0x04],
    ']': [0x04, 0x3e],
    '*': 0x14,
    $: 0x30,
    '…': [0x04, 0x04, 0x04],
    '—': [0x24, 0x24],
    º: [0x15],
    ª: [0x01],
    '/': [0x20, 0x02],
    '\\': [0x10, 0x04],
    _: [0x38],
    '{': [0x28],
    '}': [0x34],
    '●': [0x2a, 0x15],
    '•': [0x2a, 0x15],
    '‣': [0x2a, 0x15],
    '∙': [0x2a, 0x15],
    '◆': [0x2a, 0x15],
    '○': [0x2a, 0x15],
    '⚫': [0x2a, 0x15],
    '⚪': [0x2a, 0x15],
    '◦': [0x2a, 0x15],
    '▪': [0x38, 0x3d],
    '▫': [0x38, 0x3d],
    '■': [0x38, 0x3d],
    '□': [0x38, 0x3d],
    '▢': [0x38, 0x3d],
    '@': [0x31],
    '%': [0x38, 0x34],
    '¶': [0x32],
    '©': [0x23, 0x28, 0x09, 0x1c],
    '®': [0x23, 0x28, 0x17, 0x1c],
    '÷': 0x32,
    '<': 0x2a,
    '>': 0x15,
    '″': 0x20,
    '§': [0x0e, 0x0e],
    '°': 0x34,
    '′': 0x33,
    '¥': 0x3d,
    '£': 0x3e,
    '€': [0x08, 0x09],
    '#': 0x3c,
    '»': [0x30, 0x26],
    '›': [0x30, 0x26],
    '«': [0x30, 0x26],
    '‹': [0x30, 0x26],
    '–': [0x24, 0x24],
    '|': 0x38,
    '=': 0x36,
    '`': 0x20,
    '~': 0x10,
    '&': 0x2f,
    '‰': [0x38, 0x34, 0x34],
    '+': 0x16,
    '─': 0x24,
    '←': [0x2a, 0x12],
    '→': [0x12, 0x15],
    '↔': [0x2a, 0x12, 0x15],
    '✦': [0x28, 0x2a],
    '†': [0x3a, 0x02],
    '♀': [0x2a, 0x15, 0x3a, 0x02],
    '♂': [0x2a, 0x15, 0x12, 0x15],
    '⚧': [0x2a, 0x15, 0x32, 0x13],
    '✓': [0x22, 0x0c],
    '×': [0x28, 0x26],
    '¬': [0x18],
    '^': [0x08],
    '≠': [0x18, 0x36],
    '≡': [0x36, 0x36],
    '∆': [0x18, 0x19],
    µ: [0x08, 0x0d],
    '±': [0x16, 0x12, 0x24],
    '∈': [0x23, 0x02],
    '≅': [0x08, 0x36],
    Ω: [0x18, 0x3a],
    λ: [0x08, 0x07],
    θ: [0x08, 0x39], // uppercase theta
    Θ: [0x18, 0x39], // lowercase theta
    α: [0x08, 0x01], // lowercase alpha
    Α: [0x18, 0x01], // uppercase alpha
    β: [0x08, 0x03], // lowercase beta
    Β: [0x18, 0x03], // uppercase beta
    γ: [0x08, 0x1b], // lowercase gamma
    Γ: [0x18, 0x1b], // uppercase gamma
    δ: [0x08, 0x19], // lowercase delta
    Δ: [0x18, 0x19], // uppercase delta
    ε: [0x08, 0x19], // lowercase epsilon
    Ε: [0x18, 0x11], // uppercase epsilon
    ζ: [0x08, 0x35], // lowercase zeta
    Ζ: [0x18, 0x35], // uppercase zeta
    η: [0x08, 0x31], // lowercase eta
    Η: [0x18, 0x31], // uppercase eta
    ι: [0x08, 0x0a], // lowercase iota
    Ι: [0x18, 0x0a], // uppercase iota
    κ: [0x08, 0x05], // lowercase kappa
    Κ: [0x18, 0x05], // uppercase kappa
    μ: [0x08, 0x0d], // lowercase mi|mu
    Μ: [0x18, 0x0d], // uppercase mi|mu
    ν: [0x08, 0x1d], // lowercase ni|nu
    Ν: [0x18, 0x1d], // uppercase ni|nu
    ξ: [0x08, 0x2d], // lowercase xi
    Ξ: [0x18, 0x2d], // uppercase xi
    ο: [0x08, 0x15], // lowercase omicron
    Ο: [0x18, 0x15], // uppercase omicron
    π: [0x08, 0x0f], // lowercase pi
    Π: [0x18, 0x0f], // uppercase pi
    ρ: [0x08, 0x17], // lowercase rho
    Ρ: [0x18, 0x17], // uppercase rho
    σ: [0x08, 0x0e], // lowercase sigma
    Σ: [0x18, 0x0e], // uppercase sigma
    τ: [0x08, 0x1e], // lowercase tau
    Τ: [0x18, 0x1e], // uppercase tau
    υ: [0x08, 0x25], // lowercase upsilon
    Υ: [0x18, 0x25], // uppercase upsilon
    φ: [0x08, 0x0b], // lowercase phi
    Φ: [0x18, 0x0b], // uppercase phi
    χ: [0x08, 0x2f], // lowercase chi
    Χ: [0x18, 0x2f], // uppercase chi
    ψ: [0x08, 0x3d], // lowercase psi
    Ψ: [0x18, 0x3d], // uppercase psi
    ω: [0x08, 0x3a], // lowercase omega
    '╘': 0x23, // used internally (not mapped) as simple parenthesis
    '╛': 0x1c, // used internally (not mapped) as simple parenthesis
    '┐': 0x32, // used internally (not mapped)
    '╤': 0x26, // used internally (not mapped)
    '╧': 0x14, // used internally (not mapped),
    '╓': [0x37], // used internally (not mapped) as simple brackets
    '╖': [0x3e], // used internally (not mapped) as simple brackets
    '╞': [0x10, 0x02], // used internally (not mapped) as computer related context delimiter
    '╡': [0x10, 0x02], // used internally (not mapped) as computer related context delimiter
};

/**
 * @type {Object<string,  number|number[]>}
 */
export const MathContext = {
    '(': 0x23,
    ')': 0x1c,
    '—': 0x32,
    '¥': [0x08, 0x3d],
    '£': [0x08, 0x07],
    '×': 0x26,
};

/**
 * @type {Object<string,  number|number[]>}
 */
export const ComputerRelatedContext = {
    '(': [0x10, 0x23],
    ')': [0x10, 0x1c],
    '/': 0x32,
    // eslint-disable-next-line
    "'": 0x20,
    '’': 0x20,
    '|': [0x38, 0x07],
    '#': [0x3c, 0x05],
    '[': [0x10, 0x37],
    ']': [0x10, 0x3e],
    '&': [0x10, 0x2f],
    '<': [0x10, 0x2a],
    '>': [0x10, 0x15],
    _: [0x28, 0x24],
};

/**
 * @type {Object<string,  number|number[]>}
 */
export const Special = {
    RETURNER: 0x10,
    RETURNER_COMPUTER_RELATED_CONTEXT: 0x30,
    NUMBER: 0x3c,
    UPPER_CASE: 0x28,
    UPPER_CASE_WORD: [0x28, 0x28],
    UPPER_CASE_PARAGRAPH: [0x12, 0x28, 0x28], // used when exists more than 3 words in paragraph
    HIGHLIGHT: 0x14,
    SUP: 0x21,
    SUB: 0x0c,
};

export const UnicodeReference = 0x2800;

/**
 * @param char {number | number[]}
 * @returns {string}
 */
export function toUnicode(char) {
    if (Array.isArray(char)) {
        let str = '';
        for (let c of char) {
            str += String.fromCharCode(c + UnicodeReference);
        }
        return str;
    }
    return String.fromCharCode(char + UnicodeReference);
}

/**
 * @param flags {ConversionFlags[]}
 * @param char {string}
 * @returns {number | number[]}
 */
export function getBrailleFromChar(flags, char) {
    const symbol = SymbolChar[char]; // some greek symbols has lower/uppercase
    char = char.toLowerCase();
    let brailleChar;

    if (flags.includes(ConversionFlags.CONTEXT_COMPUTER_RELATED)) {
        brailleChar = ComputerRelatedContext[char];
    } else if (flags.includes(ConversionFlags.CONTEXT_MATH)) {
        brailleChar = MathContext[char];
    }

    return brailleChar ?? BasicChar[char] ?? NumberChar[char] ?? symbol;
}

import { isBrNumber, isUpperCase } from '../../util/TextUtil';
import {
    breakLinesToFitGrid,
    convertElementsToBraille,
} from '../braille/HtmlToBraille';

/**
 * @param value {string}
 * @param startStr {string | undefined}
 * @param endStr {string | undefined}
 * @param startToReplace {string}
 * @param endToReplace {string}
 * @return {string}
 */
export function replaceDelimitersComputerRelated(
    value,
    startStr = '<',
    endStr = '>',
    startToReplace,
    endToReplace,
) {
    return value.replace(
        new RegExp(`(${startStr})(.*?)(${endStr})`, 'gi'),
        `${startToReplace}$2${endToReplace}`,
    );
}

/**
 * Insert line breaks ("\n") into the given text after specified searchText occurrences
 * @param {string} txt - The text which to insert line breaks into
 * @param {{searchText: string, occurrences: number}[]} breaks - Breaks coordinates
 * @param {string} paragraphTextUntilNode
 * @returns {string}
 */
function insertBreaks(txt, breaks) {
    breaks.sort((a, b) => b.occurrences - a.occurrences);
    breaks.forEach((br) => {
        let index = 0;
        for (let i = 0; i < br.occurrences; ++i) {
            index = txt.indexOf(br.searchText, index);
            if (index === -1) break;
            index += br.searchText.length;
        }
        if (index !== -1) {
            index -= 1;
            const insert = '~\n  ';
            txt = txt.slice(0, index) + insert + txt.slice(index);
        }
    });
    return '\n' + txt;
}

/**
 * @param txt {string}
 * @param {ConversionParams} conversionParams
 * @return {string}
 */
export function extractComputerRelatedContext(txt, conversionParams) {
    const { brailleDocument, paragraphTextUntilNode } = conversionParams;

    const editorElementComputerRelated =
        document.createElement('editor-element');
    editorElementComputerRelated.setAttribute('type', 'computer-related');
    const spaces = paragraphTextUntilNode.trim() ? '  ' : '';
    editorElementComputerRelated.innerText = spaces + txt;

    const converted = convertElementsToBraille(
        [[editorElementComputerRelated]],
        brailleDocument,
    );
    if (converted[0].brailleParagraph.length - spaces.length >= 60) {
        const breaks = breakLinesToFitGrid(
            converted[0],
            brailleDocument,
        ).breaks;
        txt = insertBreaks(spaces + txt, breaks);
    }

    // when computer related context, replaces the symbols < and > to ~, (#48626)
    txt = txt.replace(/[<>]/g, '~,');
    // replace ''' to 'ý' (line 60 of spec sheet)
    txt = txt.replace(/'''/g, 'ý');
    // replace '/' to '`/' (line 61 of spec sheet)
    txt = txt.replace(/\//g, '`/');
    // replace '|' to '|l' (line 62 of spec sheet)
    txt = txt.replace(/[|]/g, '|l');
    // replace '[' to '~`[' (line 64 of spec sheet)
    txt = txt.replace(/\[/g, '~`[');
    // replace ']' to '~`]' (line 65 of spec sheet)
    txt = txt.replace(/]/g, '~`]');
    // replace '&' to '~&' (line 66 of spec sheet)
    txt = txt.replace(/&/g, '~&');
    // do first to not conflict bellow
    // replace '>' to '~>' (line 67 of spec sheet)
    txt = txt.replace(/>/g, '~>');
    // do first to not conflict bellow
    // replace '<' to '~<' (line 68 of spec sheet)
    txt = txt.replace(/</g, '~<');
    // do first to not conflict bellow
    // replace '(' to '~`(' (line 69 of spec sheet)
    txt = txt.replace(/\(/g, '~`(');
    // do first to not conflict bellow
    // replace ')' to '~`)' (line 70 of spec sheet)
    txt = txt.replace(/\)/g, '~`)');
    // replace '_' to '{-' (line 71 of spec sheet)
    txt = txt.replace(/_/g, '{-');
    // numbers are exception in computer related context (#48061)
    txt = txt.replace(/(\d[\d,.]+)([a-zA-ZÀ-ÿ]?)/g, (match, g1, g2) => {
        if (!isBrNumber(g1)) return match;
        txt =
            '#' +
            g1
                .replaceAll('1', 'a')
                .replaceAll('2', 'b')
                .replaceAll('3', 'c')
                .replaceAll('4', 'd')
                .replaceAll('5', 'e')
                .replaceAll('6', 'f')
                .replaceAll('7', 'g')
                .replaceAll('8', 'h')
                .replaceAll('9', 'i')
                .replaceAll('0', 'j');
        if (g2) {
            if (!isUpperCase(g2)) {
                if (g2[0] >= 'a' && g2[0] <= 'j') {
                    txt += '$' + g2;
                } else {
                    txt += g2;
                }
            } else {
                txt += g2;
            }
        }
        return txt;
    });

    return txt;
}

import {
    generateId,
    getBottomNodeInPage,
    getBrailleDocument,
    getClosestEditorElement,
    getClosestElementRepresentationGenericSpace,
    getClosestElementRepresentationLineBreak,
    getClosestPage,
    isEditorElement,
    isEditorElementNthRoot,
    isEditorPage,
    selectAllTextNode,
} from './EditorUtil';
import { ZERO_WIDTH_NB_CHAR, ZERO_WIDTH_SPACE_CHAR } from '../KeyboardModule';
import { getCaretPosition } from './CaretPath';
import { getImageUrlDataFromBackgroundImage } from 'plataforma-braille-common';
import { getCurrentPage } from './PageManipulation';

/**
 * @param editor {EditorCustom | null}
 * @param legend {string | null}
 * @param description {string | null}
 * @param page {string | null}
 * @param image {string | null}
 * @returns {HTMLElement}
 */
export function createEditorElementImageLayout(
    editor,
    legend,
    description,
    page,
    image,
) {
    const pageNumberDiv = document.createElement('div');
    pageNumberDiv.setAttribute('class', 'page-number');
    pageNumberDiv.setAttribute('contentEditable', 'true');
    // I18N
    pageNumberDiv.setAttribute('data-placeholder', 'Pág.: ');
    pageNumberDiv.innerHTML = page?.toString()?.length
        ? page?.toString()
        : ZERO_WIDTH_NB_CHAR;

    const imageLabelDiv = document.createElement('div');
    imageLabelDiv.setAttribute('class', 'info-legend');
    imageLabelDiv.setAttribute('contentEditable', 'true');
    // I18N
    imageLabelDiv.setAttribute('data-placeholder', 'Legenda: ');
    imageLabelDiv.innerHTML = legend?.trim().length
        ? legend.trim()
        : ZERO_WIDTH_NB_CHAR;

    const imageDescriptionDiv = document.createElement('div');
    imageDescriptionDiv.setAttribute('class', 'info-description');
    imageDescriptionDiv.setAttribute('contentEditable', 'true');
    // I18N
    imageDescriptionDiv.setAttribute('data-placeholder', 'Descrição: ');
    imageDescriptionDiv.innerHTML = description?.trim().length
        ? description.trim()
        : ZERO_WIDTH_NB_CHAR;

    const editorElement = document.createElement('editor-element');
    const elementId = generateId(editor, 'editor-element-image-layout');
    editorElement.setAttribute('id', elementId);
    editorElement.setAttribute('type', 'image-layout');
    editorElement.setAttribute('contentEditable', 'false');
    if (image?.trim()) {
        editorElement.setAttribute('data-image', image);
    }
    editorElement.appendChild(document.createTextNode('_y'));
    editorElement.appendChild(pageNumberDiv);
    editorElement.appendChild(document.createTextNode(':\u00A0'));
    editorElement.appendChild(imageLabelDiv);
    editorElement.appendChild(document.createTextNode('\u00A0['));
    editorElement.appendChild(imageDescriptionDiv);
    editorElement.appendChild(document.createTextNode(']'));

    return editorElement;
}

/**
 * @param editor {EditorCustom | null}
 * @param legend {string | null}
 * @param description {string | null}
 * @param page {string | null}
 * @param image {string | null}
 * @returns {HTMLElement}
 */
export function createEditorElementImage(
    editor,
    legend,
    description,
    page,
    image,
) {
    const editorElement = document.createElement('editor-element');
    const elementId = generateId(editor, 'editor-element-image');
    editorElement.setAttribute('contentEditable', 'false');
    editorElement.setAttribute('type', 'image');
    editorElement.setAttribute('id', elementId);

    const infoContainer = document.createElement('div');
    infoContainer.setAttribute('class', 'info-container');
    editorElement.appendChild(infoContainer);

    const imageLabelDiv = document.createElement('div');
    imageLabelDiv.setAttribute('class', 'info-legend');
    imageLabelDiv.setAttribute('contentEditable', 'true');
    // I18N
    imageLabelDiv.setAttribute('data-placeholder', 'Legenda: ');
    editorElement.appendChild(infoContainer);
    infoContainer.appendChild(imageLabelDiv);
    imageLabelDiv.innerHTML = legend?.trim().length
        ? legend.trim()
        : ZERO_WIDTH_NB_CHAR;

    const imageDescriptionDiv = document.createElement('div');
    imageDescriptionDiv.setAttribute('class', 'info-description');
    imageDescriptionDiv.setAttribute('contentEditable', 'true');
    // I18N
    imageDescriptionDiv.setAttribute('data-placeholder', 'Descrição: ');
    infoContainer.appendChild(imageDescriptionDiv);
    imageDescriptionDiv.innerHTML = description?.trim().length
        ? description.trim()
        : ZERO_WIDTH_NB_CHAR;

    const pageContainer = document.createElement('div');
    pageContainer.setAttribute('class', 'page-container');
    editorElement.appendChild(pageContainer);

    const pageNumberDiv = document.createElement('div');
    pageNumberDiv.setAttribute('class', 'page-number');
    pageNumberDiv.setAttribute('contentEditable', 'true');
    // I18N
    pageNumberDiv.setAttribute('data-placeholder', 'Pág.: ');
    pageContainer.appendChild(pageNumberDiv);

    if (image) {
        editorElement.style.backgroundImage = `url("${image}")`;
    }

    pageNumberDiv.innerHTML = page?.toString()?.length
        ? page?.toString()
        : ZERO_WIDTH_NB_CHAR;
    return editorElement;
}

/**
 * @param editor {EditorCustom}
 */
export function insertEditorElementImage(editor) {
    const selection = editor.selection?.getContent().trim() ?? '';
    const convertedToBraille = getBrailleDocument(editor)?.convertedToBraille;
    const editorElement = convertedToBraille
        ? createEditorElementImageLayout(editor, selection, null, null, null)
        : createEditorElementImage(editor, selection, null, null, null);

    if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
        return;
    }

    editor.undoManager.transact(() => {
        const documentFragment = document.createDocumentFragment();
        documentFragment.appendChild(editorElement);
        addInvisibleParagraphBreak(editorElement);

        // attach the editor-element at cursor
        if (isEditorPage(editor.selection.getNode())) {
            // noinspection JSCheckFunctionSignatures
            editor.selection.setNode(documentFragment);
        } else {
            editor.selection.setContent('');
            let insertAt = editor.selection.getNode();
            insertAt = getBottomNodeInPage(insertAt);
            if (!insertAt) {
                console.warn(
                    'No container found to insert editor element image.',
                );
                return;
            }
            insertAt.replaceWith(insertAt, documentFragment);
        }

        // after the element is attached, the tinymce generates another instance of element, so it's necessarily found the element
        // to put the cursor inside it
        const toSelect = editor.dom.get(editorElement.getAttribute('id'));
        if (toSelect) {
            editor.focus();
            editor.selection.setCursorLocation(
                toSelect.querySelector(
                    convertedToBraille ? '.page-number' : '.info-legend',
                ),
                0,
            );
        }
    });
}

/**
 * @param editor {EditorCustom}
 * @param element {HTMLElement | Node}
 * @returns {boolean}
 */
export function elementCanBeInsertedAtSelection(editor, element) {
    if (!getClosestPage(editor.selection.getNode())) {
        editor.notificationManager.open({
            // I18N
            text: 'Não é possível inserir elementos fora da página',
            type: 'warning',
            timeout: 5000,
        });
    } else if (isEditorElement(element)) {
        if (
            checkAncestorsElement(editor.selection.getNode(), (el) =>
                isEditorElement(el),
            )
        ) {
            let representation = getClosestElementRepresentationLineBreak(
                editor.selection.getNode(),
            );

            // exception when showing non-printable chars
            if (representation) {
                const textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
                representation.parentNode.insertBefore(
                    textNode,
                    representation,
                );
                editor.selection.setCursorLocation(textNode, 0);
                return true;
            }
            representation = getClosestElementRepresentationGenericSpace(
                editor.selection.getNode(),
            );
            if (representation) {
                const textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
                // noinspection JSCheckFunctionSignatures
                representation.replaceWith(representation, textNode);
                editor.selection.setCursorLocation(textNode, 0);
                return true;
            }

            editor.notificationManager.open({
                // I18N
                text: 'Não é possível inserir elementos em cascata',
                type: 'warning',
                timeout: 5000,
            });
            return false;
        }
    }
    return true;
}

/**
 * @param node { Node }
 * @param testFn { (element: HTMLElement | Node) => boolean }
 * @returns {boolean}
 */
export function checkAncestorsElement(node, testFn) {
    if (!node) return false;
    if (testFn(node)) return true;
    return checkAncestorsElement(node.parentNode, testFn);
}

/**
 * @param element {HTMLElement | Node}
 */
export function addInvisibleParagraphBreak(element) {
    /**
     * @type {HTMLElement}
     */
    let br = element.nextElementSibling;
    if (br?.tagName === 'BR') {
        br.style.display = 'none';
    } else {
        br = document.createElement('br');
        br.style.display = 'none';
        element.replaceWith(element, br);
    }
}

/**
 * @param editor {EditorCustom}
 */
export function removeEditorElementSel(editor) {
    const node = getClosestEditorElement(editor.selection.getNode());
    if (!node) {
        return;
    }
    editor.undoManager.transact(() => {
        const caretPosition = getCaretPosition(editor);
        node.remove();
        /**
         * @type {PageDataChangedEvent}
         */
        const pageDataChangedEvent = { caretPosition: caretPosition };
        editor.fire('pageDataChanged', pageDataChangedEvent);
    });
}

/**
 * @param editor {EditorCustom}
 * @return {HTMLElement | null}
 */
export function insertNthRoot(editor) {
    if (!getCurrentPage(editor)) return null;
    const elementId = generateId(editor, 'editor-element-nth-root');
    editor.undoManager.transact(() => {
        const editorElement = editor.dom.create('editor-element', {
            id: elementId,
            type: 'nth-root',
            contenteditable: false,
        });
        const index = editor.dom.create('div', {
            class: 'index',
            contenteditable: true,
        });
        const radicand = editor.dom.create('div', {
            class: 'radicand',
            contenteditable: true,
        });
        index.innerHTML = '<br>';
        radicand.innerHTML = '<br>';
        editorElement.appendChild(index);
        editorElement.appendChild(radicand);
        editor.selection.setContent(editorElement.outerHTML);
    });
    const element = editor.dom.get(elementId);
    const index = element.querySelector('.index');
    editor.focus();
    surroundElementWithZeroWidthSpace(element);
    editor.selection.setCursorLocation(index.firstElementChild, 0);
    /**
     * @type {PageDataChangedEvent}
     */
    const pageDataChangedEvent = { caretPosition: getCaretPosition(editor) };
    editor.fire('pageDataChanged', pageDataChangedEvent);
    return element;
}

/**
 * @param editor {EditorCustom}
 * @return {HTMLElement}
 */
export function insertLineSegment(editor) {
    if (!getCurrentPage(editor)) return;
    const elementId = generateId(editor, 'editor-element-line-segment');
    editor.undoManager.transact(() => {
        const editorElement = editor.dom.create('editor-element', {
            id: elementId,
            type: 'line-segment',
            contenteditable: false,
        });
        const value = editor.dom.create('div', {
            class: 'value',
            contenteditable: true,
        });
        editorElement.appendChild(value);
        editor.selection.setContent(editorElement.outerHTML);
    });
    const element = editor.dom.get(elementId);
    const value = element.querySelector('.value');
    value.innerText = 'AB';
    editor.focus();
    surroundElementWithZeroWidthSpace(element);
    selectAllTextNode(editor, value.firstChild);
    /**
     * @type {PageDataChangedEvent}
     */
    const pageDataChangedEvent = { caretPosition: getCaretPosition(editor) };
    editor.fire('pageDataChanged', pageDataChangedEvent);
    return element;
}

/**
 * @param editor {EditorCustom}
 */
export function insertAngle(editor) {
    if (!getCurrentPage(editor)) return;
    const elementId = generateId(editor, 'editor-element-angle');
    editor.undoManager.transact(() => {
        const editorElement = editor.dom.create('editor-element', {
            id: elementId,
            type: 'angle',
            contenteditable: false,
        });
        const value = editor.dom.create('div', {
            class: 'value',
            contenteditable: true,
        });
        editorElement.appendChild(value);
        editor.selection.setContent(editorElement.outerHTML);
    });
    const element = editor.dom.get(elementId);
    const value = element.querySelector('.value');
    value.innerText = 'AOB';
    editor.focus();
    surroundElementWithZeroWidthSpace(element);
    selectAllTextNode(editor, value.firstChild);
    /**
     * @type {PageDataChangedEvent}
     */
    const pageDataChangedEvent = { caretPosition: getCaretPosition(editor) };
    editor.fire('pageDataChanged', pageDataChangedEvent);
    return element;
}

/**
 * @param element {HTMLElement}
 */
export function surroundElementWithZeroWidthSpace(element) {
    element.replaceWith(
        document.createTextNode(ZERO_WIDTH_SPACE_CHAR),
        element,
        document.createTextNode(ZERO_WIDTH_SPACE_CHAR),
    );
}

/**
 * @param editor {EditorCustom}
 * @param page {HTMLElement}
 */
export function convertEditorElementImageToLayout(editor, page) {
    const editorElements = page.querySelectorAll(
        'editor-element[type="image"]',
    );
    for (let imageElement of editorElements) {
        const legend = imageElement.querySelector('.info-legend');
        const description = imageElement.querySelector('.info-description');
        const pageNumber = imageElement.querySelector('.page-number');
        const img = imageElement.style.backgroundImage;
        const newElement = createEditorElementImageLayout(
            editor,
            '',
            '',
            '',
            null,
        );
        newElement.querySelector('.info-legend').replaceWith(legend);
        newElement.querySelector('.info-description').replaceWith(description);
        newElement.querySelector('.page-number').replaceWith(pageNumber);
        newElement.setAttribute('data-image', img);
        imageElement.replaceWith(newElement);
    }
}

/**
 * @param editor {EditorCustom}
 * @param page {HTMLElement | Node}
 */

export function prepareEditorElementImageLayout(editor, page) {
    for (let element of page.querySelectorAll(
        'editor-element[type="image-layout"]',
    )) {
        // converts previous versions to most recently
        const needsConversionOrRepair =
            !element.querySelector('.info-legend') || // check if broken
            !element.querySelector('.info-description') || // check if broken
            !element.querySelector('.page-number'); // check if broken
        if (needsConversionOrRepair) {
            const page = element.querySelector('.page-number');
            const legend = element.querySelector('.info-legend');
            const description = element.querySelector('.info-description');
            const image = element.getAttribute('data-image');
            const newElement = createEditorElementImageLayout(
                editor,
                '',
                '',
                '',
                image,
            );
            if (page)
                newElement.querySelector('.page-number')?.replaceWith(page);
            if (legend)
                newElement.querySelector('.info-legend')?.replaceWith(legend);
            if (description)
                newElement
                    .querySelector('.info-description')
                    ?.replaceWith(description);
            element.replaceWith(newElement);
            element = newElement;
        } else {
            const infoLegend = element.querySelector('.info-legend');
            const infoDescription = element.querySelector('.info-description');
            const pageNumber = element.querySelector('.page-number');
            if (!infoLegend.innerText.trim())
                infoLegend.innerHTML = ZERO_WIDTH_NB_CHAR;
            if (!infoDescription.innerText.trim())
                infoDescription.innerHTML = ZERO_WIDTH_NB_CHAR;
            if (!pageNumber.innerText.trim())
                pageNumber.innerHTML = ZERO_WIDTH_NB_CHAR;
        }
        addInvisibleParagraphBreak(element);
    }
}

/**
 * @param editor {EditorCustom}
 * @param page {HTMLElement}
 */
export function unConvertEditorElementImageLayout(editor, page) {
    const editorElements = page.querySelectorAll(
        'editor-element[type="image-layout"]',
    );
    for (let imageLayoutElement of editorElements) {
        const legend = imageLayoutElement.querySelector('.info-legend');
        const description =
            imageLayoutElement.querySelector('.info-description');
        const pageNumber = imageLayoutElement.querySelector('.page-number');
        const img = imageLayoutElement.getAttribute('data-image');
        const newElement = createEditorElementImage(editor, '', '', '', null);
        newElement.querySelector('.info-legend').replaceWith(legend);
        newElement.querySelector('.info-description').replaceWith(description);
        newElement.querySelector('.page-number').replaceWith(pageNumber);
        newElement.style.backgroundImage = img;
        imageLayoutElement.replaceWith(newElement);
    }
}

/**
 * @param editor {EditorCustom}
 * @param page {HTMLElement | Node}
 */
export function prepareEditorElementImage(editor, page) {
    for (let element of page.querySelectorAll('editor-element[type="image"]')) {
        // converts previous versions to most recently
        const needsConversionOrRepair =
            !!element.querySelector('.miniature img') || // first format
            !!element.getAttribute('data-legend') || // second format
            !!element.getAttribute('data-description') || // second format
            !!element.getAttribute('data-page') || // second format
            !!element.getAttribute('data-image') || // second format
            !element.querySelector('.info-container .info-legend') || // check if broken
            !element.querySelector('.info-container .info-description') || // check if broken
            !element.querySelector('.page-container .page-number'); // check if broken
        if (needsConversionOrRepair) {
            const legend =
                element.getAttribute('data-legend') ??
                element.querySelector('.info-legend')?.innerHTML;
            const description =
                element.getAttribute('data-description') ??
                element.querySelector('.info-description')?.innerHTML;
            const page =
                element.getAttribute('data-page') ??
                element.querySelector('.page-number')?.innerHTML;
            const image =
                element.getAttribute('data-img') ??
                element
                    .querySelector('.miniature img')
                    ?.getAttribute('data-src') ??
                element.querySelector('.miniature img')?.src ??
                getImageUrlDataFromBackgroundImage(
                    element.style.backgroundImage,
                );
            const newElement = createEditorElementImage(
                editor,
                '',
                '',
                '',
                image,
            );
            if (page)
                newElement.querySelector('.page-number')?.replaceWith(page);
            if (legend)
                newElement.querySelector('.info-legend')?.replaceWith(legend);
            if (description)
                newElement
                    .querySelector('.info-description')
                    ?.replaceWith(description);
            element.replaceWith(newElement);
            element = newElement;
        } else {
            const infoLegend = element.querySelector('.info-legend');
            const infoDescription = element.querySelector('.info-description');
            const pageNumber = element.querySelector('.page-number');
            if (!infoLegend.innerText.trim())
                infoLegend.innerHTML = ZERO_WIDTH_NB_CHAR;
            if (!infoDescription.innerText.trim())
                infoDescription.innerHTML = ZERO_WIDTH_NB_CHAR;
            if (!pageNumber.innerText.trim())
                pageNumber.innerHTML = ZERO_WIDTH_NB_CHAR;
        }
        addInvisibleParagraphBreak(element);
    }
}

/**
 * @param editor {EditorCustom}
 * @param page
 */
export function prepareEditorElementMathContext(editor, page) {
    for (let element of [
        ...page.querySelectorAll('editor-element[type="nth-root"]'),
        ...page.querySelectorAll('editor-element[type="line-segment"]'),
        ...page.querySelectorAll('editor-element[type="angle"]'),
    ]) {
        // check and remove corrupted elements
        if (isEditorElementNthRoot(element)) {
            const index = element.querySelector('.index');
            const radicand = element.querySelector('.radicand');
            if (!index || !radicand) {
                element.remove();
                continue;
            }
            if (!index.innerHTML.trim().length) index.innerHTML = '<br>';
            if (!radicand.innerHTML.trim().length) radicand.innerHTML = '<br>';
        } else {
            const value = element.querySelector('.value');
            if (!value) {
                element.remove();
                continue;
            }
            if (!value.innerHTML.trim().length) value.innerHTML = '<br>';
        }

        // with the zero width space char around the element enables the mouse selection prior or after the element when
        // his alone in paragraph
        if (
            !element.previousSibling?.nodeValue?.endsWith(ZERO_WIDTH_SPACE_CHAR)
        ) {
            element.replaceWith(
                document.createTextNode(ZERO_WIDTH_SPACE_CHAR),
                element,
            );
        }
        if (
            !element.nextSibling?.nodeValue?.startsWith(ZERO_WIDTH_SPACE_CHAR)
        ) {
            element.replaceWith(
                element,
                document.createTextNode(ZERO_WIDTH_SPACE_CHAR),
            );
        }
    }
}

/**
 * @param editor {EditorCustom}
 */
export function insertRecoil(editor) {
    editor.selection.setContent(
        `<br>&lt;r+&gt;<br>${editor.selection.getContent()}<br>&lt;r-&gt;<br>`,
    );
}

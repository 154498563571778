import { RoleEnum } from "./role.enum";

export enum DocumentStatusEnum {
    LINEARIZATION = "LINEARIZATION",
    DESCRIPTION = "DESCRIPTION",
    LAYOUT = "LAYOUT",
    EVALUATION = "EVALUATION",
    EDITORIAL_COMPLETED = "EDITORIAL_COMPLETED",
    PRINTING_APPROVAL = "PRINTING_APPROVAL",
}

export type DocumentStatusEnumValue =
    (typeof DocumentStatusEnum)[keyof typeof DocumentStatusEnum];

export function DocumentStatusEnumToString(key: string): string {
    const role = DocumentStatusEnum[key];
    switch (role) {
        case DocumentStatusEnum.LINEARIZATION:
            // I18N
            return "Linearização";
        case DocumentStatusEnum.DESCRIPTION:
            // I18N
            return "Descrição";
        case DocumentStatusEnum.LAYOUT:
            // I18N
            return "Diagramação";
        case DocumentStatusEnum.EVALUATION:
            // I18N
            return "Avaliação";
        case DocumentStatusEnum.EDITORIAL_COMPLETED:
            // I18N
            return "Editorial finalizado";
        case DocumentStatusEnum.PRINTING_APPROVAL:
            // I18N
            return "Aprovação de impressão";
        default:
            throw new Error("Invalid enum value");
    }
}

export function getDocumentStatusArray(): DocumentStatusEnumValue[] {
    const array = [];
    for (const status in DocumentStatusEnum) {
        array.push(status);
    }
    return array;
}

export function getDocumentStatusUserRoleEnum(
    documentStatus: DocumentStatusEnum,
): RoleEnum | null {
    switch (documentStatus) {
        default:
            return null;
        case DocumentStatusEnum.LINEARIZATION:
            return RoleEnum.LINEARIZATION;
        case DocumentStatusEnum.DESCRIPTION:
            return RoleEnum.DESCRIPTION;
        case DocumentStatusEnum.EVALUATION:
            return RoleEnum.EVALUATION;
        case DocumentStatusEnum.LAYOUT:
            return RoleEnum.LAYOUT;
    }
}

/**
 * @param textToCopy {string}
 * @return {Promise<void>}
 */
export async function copyToClipboard(textToCopy) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
    } else {
        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;

        // Move textarea out of the viewport, so it's not visible
        textArea.style.position = 'absolute';
        textArea.style.left = '-999999px';
        textArea.style.opacity = '0';

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } finally {
            textArea.remove();
        }
    }
}

import './RevisionModule.scss';
import { StatusEnum } from './StatusEnum';
import {
    FormattingType,
    generateId,
    isInsideEditorElementParagraphBreak,
} from '../core/EditorUtil';
import { InvalidCharInspection } from './inspections/InvalidCharInspection';
import {
    getCaretPosition,
    INNER_CONTEXT_CONTAINER_CLASSES,
    scanCaretPath,
    setCaretPosition,
} from '../core/CaretPath';
import { applyFormatting, removeFormatting } from '../core/Formatting';
import {
    getCurrentPage,
    getPages,
    replacePageContents,
} from '../core/PageManipulation';
import { InvalidUseOfApostrophe } from './inspections/InvalidUseOfApostrophe';
import { TextMergedWithQuestionNumber } from './inspections/TextMergedWithQuestionNumber';
import { OrdinalFollowedByTempMeasure } from './inspections/OrdinalFollowedByTempMeasure';
import { DegreeNotFollowedByTempMeasure } from './inspections/DegreeNotFollowedByTempMeasure';
import { CurrencyFollowedBySpace } from './inspections/CurrencyFollowedBySpace';
import { TextMergedWithAlternative } from './inspections/TextMergedWithAlternative';
import { MultiplesSpacesInsideText } from './inspections/MultiplesSpacesInsideText';
import pLimit from 'p-limit';
import { RevisionErrorEnumToString } from './RevisionErrorEnum';
import {
    cachePageIfNotVisible,
    getPageCache,
    isPageCached,
    setPageCacheLock,
} from '../core/Cache';
import { delay } from '../../../../util/Delay';
import { showNonPrintableChars } from '../core/ShowNonPrintableChars';
import { REVISION_MODULE_VERSION } from './RevisionModuleVersion';
import { isDebugEnabled } from '../core/CoreModule';
import { OrdinalNotFollowedBySpace } from './inspections/OrdinalNotFollowedBySpace';

/**
 * @typedef {object} RevisionCheck
 * @property {function(pageNumber: number, page: HTMLElement | Node): Promise<import('RevisionRecord').RevisionRecord[]>} check
 * @property {function()} releaseWorker
 */

/**
 * @type {Object<string, string>}
 */
const statusBarCss = {
    [StatusEnum.OK]: 'status-ok',
    [StatusEnum.WARNING]: 'status-warning',
    [StatusEnum.ERROR]: 'status-error',
};

export const REVISION_LOGGER_ID = '[RevisionModule]';

const CHECK_DOCUMENT_DELAY = 1500;

export class RevisionModule {
    /**
     * @type {EditorCustom}
     */
    editor;
    /**
     * @type {RevisionModalFunctions}
     */
    revisionModal;

    /**
     * @type {HTMLElement | null}
     */
    statusBar = null;

    /**
     * @type {HTMLElement | null}
     */
    statusBarIcon = null;

    /**
     * @type {HTMLElement | null}
     */
    statusBarText = null;

    /**
     * @type {StatusEnumValue}
     */
    currentStatus = StatusEnum.UNAVAILABLE;

    /**
     * @type {Object<number, RevisionRecord[]>}
     */
    revisionRecords = {};

    /**
     * @type {number | null}
     */
    checkDocumentTimer = null;

    /**
     * @type {boolean}
     */
    checkDocumentInExecution = false;

    /**
     * @type {number}
     */
    pageDataChangedLastTime = 0;

    /**
     * @param {EditorCustom} editor
     * @param {RevisionModalFunctions} revisionModal
     */
    constructor(editor, revisionModal) {
        this.editor = editor;
        this.revisionModal = revisionModal;
    }

    debug(...data) {
        if (isDebugEnabled()) {
            console.debug(REVISION_LOGGER_ID, ...data);
        }
    }

    /**
     * @return {RevisionCheck[]}
     */
    getRevisionsCheck() {
        return [
            new InvalidCharInspection(),
            new InvalidUseOfApostrophe(),
            new TextMergedWithQuestionNumber(),
            new OrdinalFollowedByTempMeasure(),
            new DegreeNotFollowedByTempMeasure(),
            new CurrencyFollowedBySpace(),
            new TextMergedWithAlternative(),
            new MultiplesSpacesInsideText(),
            new OrdinalNotFollowedBySpace(),
        ];
    }

    updateStatusBarStatus() {
        for (const className of Object.values(statusBarCss)) {
            this.statusBar.classList.remove(className.toString());
        }
        switch (this.currentStatus) {
            default:
            case StatusEnum.UNAVAILABLE:
                // I18N
                this.statusBarText.innerText = 'Verificando...';
                // I18N
                this.statusBar.title = 'Verificando documento...';
                break;
            case StatusEnum.OK:
                // I18N
                this.statusBarText.innerText = 'Nenhum problema';
                // I18N
                this.statusBar.title = 'Nenhum problema encontrado';
                break;
            case StatusEnum.WARNING:
                // I18N
                this.statusBarText.innerText = 'Atenção';
                // I18N
                this.statusBar.title = 'Alguns itens requerem sua atenção';
                break;
            case StatusEnum.ERROR:
                // I18N
                this.statusBarText.innerText = 'Problemas encontrados';
                // I18N
                this.statusBar.title = 'Problemas encontrados no documento';
                break;
        }
        const css = statusBarCss[this.currentStatus];
        if (css) this.statusBar.classList.add(css);
    }

    /**
     * @param element {HTMLElement | DocumentFragment}
     */
    clearInnerContextElement(element) {
        for (const containerClass of INNER_CONTEXT_CONTAINER_CLASSES) {
            for (const container of element.querySelectorAll(containerClass)) {
                const { fragment, formatting } = removeFormatting(container);
                formatting[FormattingType.REVISION_ERROR] = [];
                applyFormatting(fragment, formatting);
                container.innerHTML = '';
                container.appendChild(fragment);
            }
        }
    }

    /**
     * @param page {HTMLElement | Node}
     * @param revisionErrors {RevisionRecord[]}
     * @return {void}
     */
    showRevisionErrors(page, revisionErrors) {
        this.sortRevisionRecords(revisionErrors);
        const self = this;
        const { fragment, formatting } = removeFormatting(page);
        this.clearInnerContextElement(fragment);
        formatting[FormattingType.REVISION_ERROR] = [];
        let paragraphMap = { 0: 0 };
        let paragraph = 0;
        const pagePath = scanCaretPath(fragment);
        for (const [i, path] of pagePath.entries()) {
            if (path === '\n') {
                paragraph++;
                paragraphMap[paragraph] = i + 1;
            }
        }
        /**
         * @type {Object<string, number[]>}
         */
        let innerFormattingMap = {};
        /**
         * @type {Object<number, RevisionRecord[]>}
         */
        let revisionErrorMap = {};
        /**
         * @type {Object<string, Object<number, RevisionRecord[]>>}
         */
        let innerRevisionErrorMap = {};
        for (const revisionError of revisionErrors) {
            const innerContext =
                pagePath[paragraphMap[revisionError.paragraph]];
            if (typeof innerContext === 'object') {
                // inner context elements
                let fragmentIdx = 0;
                for (const containerClass of INNER_CONTEXT_CONTAINER_CLASSES) {
                    const container =
                        innerContext.querySelector(containerClass);
                    if (!container) continue;
                    if (revisionError.fragment !== fragmentIdx) {
                        fragmentIdx++;
                        continue;
                    }
                    let containerId = container.getAttribute('id');
                    if (!containerId) {
                        containerId = generateId(this.editor, 'element');
                        container.setAttribute('id', containerId);
                    }
                    let map = innerRevisionErrorMap[containerId];
                    if (!map) {
                        map = {};
                        innerRevisionErrorMap[containerId] = map;
                    }
                    let formattingErrors = innerFormattingMap[containerId];
                    if (!formattingErrors) {
                        formattingErrors = [];
                        innerFormattingMap[containerId] = formattingErrors;
                    }
                    const start = revisionError.pathIndex;
                    const end =
                        revisionError.pathIndex + revisionError.length - 1;
                    for (let i = start; i <= end; i++) {
                        let errors = map[i];
                        if (!errors) {
                            formattingErrors.push(i);
                            errors = [];
                            map[i] = errors;
                        }
                        errors.push(revisionError);
                    }
                    fragmentIdx++;
                }
            } else {
                // regular page elements
                const start =
                    paragraphMap[revisionError.paragraph] +
                    revisionError.pathIndex;
                const end = start + revisionError.length - 1;
                const formattingErrors =
                    formatting[FormattingType.REVISION_ERROR];
                for (let i = start; i <= end; i++) {
                    let errors = revisionErrorMap[i];
                    if (!errors) {
                        formattingErrors.push(i);
                        errors = [];
                        revisionErrorMap[i] = errors;
                    }
                    errors.push(revisionError);
                }
            }
        }

        // apply revision errors inside inner context elements
        for (const elementId of Object.keys(innerFormattingMap)) {
            const container = fragment.getElementById(elementId);
            if (!container) {
                console.warn(`Cannot find container ${elementId}`);
                continue;
            }
            let { fragment: containerFragment, formatting } = removeFormatting(
                container,
                true,
            );
            formatting[FormattingType.REVISION_ERROR] =
                innerFormattingMap[elementId];
            const map = innerRevisionErrorMap[elementId];
            applyFormatting(
                containerFragment,
                formatting,
                (container, formattingType, position, length) => {
                    if (formattingType === FormattingType.REVISION_ERROR) {
                        /**
                         * @type {Set<RevisionRecord>}
                         */
                        let allContainerErrors = new Set();
                        for (let i = position; i < position + length; i++) {
                            const errors = map[i];
                            if (!errors) continue;
                            for (const error of errors)
                                allContainerErrors.add(error);
                        }
                        self.updateEditorElementRevisionError(container, [
                            ...allContainerErrors,
                        ]);
                    }
                },
            );
            container.innerHTML = '';
            container.appendChild(containerFragment);
        }

        applyFormatting(
            fragment,
            formatting,
            (container, formattingType, position, length) => {
                if (formattingType === FormattingType.REVISION_ERROR) {
                    /**
                     * @type {Set<RevisionRecord>}
                     */
                    let allContainerErrors = new Set();
                    for (let i = position; i < position + length; i++) {
                        const errors = revisionErrorMap[i];
                        if (!errors) continue;
                        for (const error of errors)
                            allContainerErrors.add(error);
                    }
                    self.updateEditorElementRevisionError(container, [
                        ...allContainerErrors,
                    ]);
                }
            },
        );
        const currentPage = getCurrentPage(this.editor);
        const paragraphsChanges = this.getBrailleParagraphsChanges(page);
        this.restoreBrailleParagraphBreaks(fragment, paragraphsChanges);
        /**
         * @type {CaretPosition | null}
         */
        let caretPosition = null;
        if (page === currentPage) {
            caretPosition = getCaretPosition(this.editor);
        }
        replacePageContents(page, fragment);
        this.editor.custom.coreModule.prepareEditorElements(page);
        if (this.editor.custom.isShowingNonPrintableChars) {
            showNonPrintableChars(this.editor, page);
        }
        if (caretPosition) {
            setCaretPosition(
                this.editor,
                caretPosition.contextElement,
                caretPosition.path,
            );
        }
    }

    /**
     * @param page {HTMLElement}
     * @return {ParagraphChange[]}
     */
    getBrailleParagraphsChanges(page) {
        /**
         * @type {Object<number, Break[]>}
         */
        let paragraphsChanges = {};
        let lastPathLength = -1;
        scanCaretPath(
            page,
            null,
            null,
            (path, paragraph, word, node) => {
                if (lastPathLength === path.length) return;
                lastPathLength = path.length;
                if (isInsideEditorElementParagraphBreak(node)) {
                    /**
                     * @type {Break}
                     */
                    const breakData = JSON.parse(
                        node.getAttribute('data-break'),
                    );
                    if (!breakData) return;
                    let breaks = paragraphsChanges[paragraph];
                    if (!breaks) {
                        breaks = [];
                        paragraphsChanges[paragraph] = breaks;
                    }
                    breaks.push(breakData);
                }
            },
            true,
            true,
        );
        /**
         * @type {ParagraphChange[]}
         */
        const result = [];
        for (const paragraph of Object.keys(paragraphsChanges)) {
            result.push({
                paragraph: parseInt(paragraph),
                breaks: paragraphsChanges[paragraph].sort((a, b) => {
                    if (a.wordIdx === b.wordIdx) {
                        return b.wordOffset ?? 0 - a.wordOffset ?? 0;
                    }
                    return b.wordIdx - a.wordIdx;
                }),
            });
        }
        return result;
    }

    /**
     * @param page {HTMLElement | Node | DocumentFragment}
     * @param paragraphsChanges {ParagraphChange[]}
     */
    restoreBrailleParagraphBreaks(page, paragraphsChanges) {
        if (!this.editor.custom.brailleDocument.convertedToBraille) return;
        const brailleView = this.editor.custom.coreModule.brailleView;
        brailleView.breakParagraphs(page, paragraphsChanges);
    }

    /**
     * @param editorElement {HTMLElement}
     * @return {string}
     */
    getEditorElementRevisionErrorId(editorElement) {
        let editorElementId = editorElement.getAttribute('id');
        if (!editorElementId) {
            editorElementId = generateId(
                this.editor,
                'editor-element-revision-error',
            );
            editorElement.setAttribute('id', editorElementId);
        }
        return editorElementId;
    }

    /**
     * @param editorElement {HTMLElement}
     * @param revisionErrors {RevisionRecord[]}
     */
    updateEditorElementRevisionError(editorElement, revisionErrors) {
        /**
         * @type {RevisionRecord[]}
         */
        let dataRevisionError = JSON.parse(
            editorElement.getAttribute('data-revision-error'),
        );
        const editorElementId =
            this.getEditorElementRevisionErrorId(editorElement);
        if (!dataRevisionError) {
            dataRevisionError = [];
        }
        dataRevisionError.push(...revisionErrors);
        editorElement.setAttribute(
            'data-revision-error',
            JSON.stringify(dataRevisionError),
        );
        let title = '';
        for (const error of dataRevisionError) {
            title += RevisionErrorEnumToString(error.inspectionError) + '\n';
            error.editorElementId = editorElementId;
        }
        editorElement.setAttribute('title', title);
    }

    /**
     * @param inspectionErrors {RevisionRecord[]}
     */
    sortRevisionRecords(inspectionErrors) {
        inspectionErrors.sort((a, b) => {
            if (a.page === b.page) {
                if (a.paragraph === b.paragraph) {
                    if (a.fragment === b.fragment) {
                        return a.pathIndex - b.pathIndex;
                    } else {
                        return a.fragment - b.fragment;
                    }
                } else {
                    return a.paragraph - b.paragraph;
                }
            } else {
                return a.page - b.page;
            }
        });
    }

    /**
     * @return {Promise<void>}
     */
    async checkDocument() {
        if (this.checkDocumentInExecution) return;
        clearTimeout(this.checkDocumentTimer);
        this.currentStatus = StatusEnum.UNAVAILABLE;
        this.updateStatusBarStatus();

        const self = this;
        this.checkDocumentTimer = setTimeout(async () => {
            this.checkDocumentInExecution = true;
            let checkDocumentAgain = false;
            const revisionsChecks = self.getRevisionsCheck();
            try {
                const pages = getPages(self.editor);
                for (let [pageIdx, page] of pages.entries()) {
                    const pageRevisionVersion = parseInt(
                        page.getAttribute('data-revision-version'),
                    );
                    const revisionOutdated =
                        isNaN(pageRevisionVersion) ||
                        pageRevisionVersion < REVISION_MODULE_VERSION;

                    if (
                        !revisionOutdated &&
                        page.getAttribute('data-needs-revision') === 'false'
                    ) {
                        continue;
                    }
                    self.debug(
                        `Checking page ${pageIdx + 1} for revision errors...`,
                    );
                    const start = new Date().getTime();
                    await this.checkPage(revisionsChecks, pageIdx, page);
                    self.debug(
                        `Page checked after ${new Date().getTime() - start} ms.`,
                    );
                    // user typing, slowdown document check to not hit page performance
                    if (
                        new Date().getTime() - self.pageDataChangedLastTime <
                        CHECK_DOCUMENT_DELAY
                    ) {
                        checkDocumentAgain = true;
                        return;
                    }
                    await delay(0);
                }
                self.checkDocumentTimer = null;
                this.updateCurrentStatus().then();
            } finally {
                for (const revisionCheck of revisionsChecks) {
                    revisionCheck.releaseWorker();
                }
                this.checkDocumentInExecution = false;
                if (checkDocumentAgain) self.checkDocument().then();
            }
        }, CHECK_DOCUMENT_DELAY);
    }

    /**
     * @param revisionsChecks {RevisionCheck[]}
     * @param pageIdx {number}
     * @param page {HTMLElement}
     * @returns {Promise<RevisionRecord[]>}
     */
    async checkPage(revisionsChecks, pageIdx, page) {
        const limit = pLimit(4);
        setPageCacheLock(this.editor, page, true);
        const pageRevisionRecords = [];
        try {
            const promises = [];
            for (const revisionCheck of revisionsChecks) {
                promises.push(
                    limit(async () => {
                        pageRevisionRecords.push(
                            ...(await revisionCheck.check(pageIdx, page)),
                        );
                    }),
                );
            }
            await Promise.allSettled(promises);
            this.showRevisionErrors(page, pageRevisionRecords);
        } finally {
            setPageCacheLock(this.editor, page, false);
            cachePageIfNotVisible(this.editor, page);
        }

        this.revisionRecords[pageIdx] = pageRevisionRecords;
        page.setAttribute('data-needs-revision', 'false');
        page.setAttribute(
            'data-revision-version',
            REVISION_MODULE_VERSION.toString(),
        );
        return pageRevisionRecords;
    }

    async updateCurrentStatus() {
        let newStatus = StatusEnum.OK;
        for (const page in this.revisionRecords) {
            const pageRevisionRecords = this.revisionRecords[page];
            for (const revisionRecord of pageRevisionRecords) {
                if (revisionRecord.revisionGravity > newStatus) {
                    newStatus = revisionRecord.revisionGravity;
                }
            }
        }
        this.currentStatus = newStatus;
        await this.updateStatusBarStatus();
    }

    harvestInspectionsErrors() {
        this.revisionRecords = [];
        const pages = getPages(this.editor);
        for (let [pageIdx, page] of pages.entries()) {
            if (page.getAttribute('data-needs-revision') !== 'false') {
                continue;
            }

            if (isPageCached(page))
                page = getPageCache(this.editor, page, false);

            const editorElements = page.querySelectorAll(
                'editor-element[type="revision-error"]',
            );
            let pageRevisionRecords = [];
            for (const editorElement of editorElements) {
                const dataRevisionError = editorElement.getAttribute(
                    'data-revision-error',
                );
                if (!dataRevisionError) {
                    // something is broken
                    console.warn(
                        'Inspection error data not found. The page will be checked again.',
                        page,
                    );
                    pages[pageIdx].setAttribute('data-needs-revision', 'true');
                    pageRevisionRecords = [];
                    break;
                }
                try {
                    /**
                     * @type {RevisionRecord[]}
                     */
                    const editorRevisionErrors = JSON.parse(dataRevisionError);
                    pageRevisionRecords.push(...editorRevisionErrors);
                    const editorElementId =
                        this.getEditorElementRevisionErrorId(editorElement);
                    for (const revisionError of editorRevisionErrors) {
                        revisionError.editorElementId = editorElementId;
                    }
                } catch (e) {
                    console.error(
                        'Fail to parse inspection error data. The page will be checked again.',
                        e,
                    );
                    pages[pageIdx].setAttribute('data-needs-revision', 'true');
                    pageRevisionRecords = [];
                }
            }
            this.revisionRecords[pageIdx] = pageRevisionRecords;
        }
    }

    /**
     * @return {RevisionRecord[]}
     */
    getAllRevisionRecords() {
        /**
         * @type {RevisionRecord[]}
         */
        const allRecords = [];
        for (const pageRevisionRecord of Object.values(this.revisionRecords)) {
            allRecords.push(...pageRevisionRecord);
        }
        return allRecords;
    }

    showRevisionModal() {
        this.revisionModal.showRevisionModal(
            this.editor,
            this.getAllRevisionRecords(),
        );
    }

    createStatusBarItem() {
        const container = this.editor.custom.customStatusBarContainer;
        this.statusBar = document.createElement('div');
        this.statusBar.className = 'revision-module-status-bar';
        this.statusBarIcon = document.createElement('div');
        this.statusBarIcon.className = 'status-icon';
        this.statusBar.appendChild(this.statusBarIcon);

        this.statusBarText = document.createElement('div');
        this.statusBarText.className = 'status-text';
        this.statusBar.appendChild(this.statusBarText);

        container.appendChild(this.statusBar);

        this.statusBar.addEventListener('click', () =>
            this.showRevisionModal(),
        );

        this.updateStatusBarStatus();

        const self = this;
        this.editor.on('pageDataChanged', (e) => {
            /**
             * @type {CaretPosition}
             */
            const caretPosition = e['caretPosition'];
            caretPosition.page?.setAttribute('data-needs-revision', 'true');
            self.pageDataChangedLastTime = new Date().getTime();
            this.checkDocument().then();
        });
        this.editor.on('pageRemoved', async ({ pageIdx }) => {
            delete this.revisionRecords[pageIdx];
            await this.updateCurrentStatus();
        });
        this.editor.on('documentReady', () => {
            self.harvestInspectionsErrors();
            self.checkDocument().then();
        });
        this.editor.on('pageOutdated', (e) => {
            /**
             *@type {HTMLElement}
             */
            const page = e['page'];
            page.setAttribute('data-needs-revision', 'true');
        });
    }

    install() {
        this.createStatusBarItem();
    }
}

import { containsUnitOfMeasurementPtBr } from '../../util/UnitsOfMeasurement';

import { isRomanNumeral } from '../../util/TextUtil';

/**
 * change parenthesis variant automatically
 * source: https://sgm.codebit.com.br/manutencao/43718
 *
 * @param txt {string}
 * @param config { undefined | {parenthesisSimple: string[], parenthesisNormal: string[], bracketsSimple: string[], bracketsNormal: string[]} }
 * @return {string}
 */
export function replaceBracketsAndParentheses(txt, config = undefined) {
    if (txt.match(/[╘╛╓╖┌┐╔╗]/g)) {
        throw new Error(
            'String contains control characters that cannot be explicit used: ' +
                txt,
        );
    }

    if (!config) {
        // this is a fast solution, for now I can see no collateral effect, in the ideal world is creating a
        // new context and update the formatting at char position, but for now I cannot spent project time on it
        config = {
            parenthesisSimple: ['╘', '╛'],
            parenthesisNormal: ['(', ')'],
            bracketsSimple: ['╓', '╖'],
            bracketsNormal: ['[', ']'],
        };
    }

    const regex = /(?<!`)([[(])([^()[\]]+?)(?<!`)([\])])/gm;
    let match;

    do {
        let output = '';
        let lastIndex = 0;
        while ((match = regex.exec(txt)) !== null) {
            const start = match.index;
            const end = regex.lastIndex;
            let open = match[1];
            let close = match[3];
            let valueToCheck = match[2]
                .replaceAll(/[╘╓].*[╖╛]/g, '') // removes inners simples parenthesis to not affect the result
                .trim();
            output += txt.slice(lastIndex, start);

            let simple = false;
            if (valueToCheck.length) {
                if (
                    open &&
                    valueToCheck[0].match(/[-+÷—×=0-9ºª]/) &&
                    valueToCheck[valueToCheck.length - 1].match(/[0-9ºª]/)
                ) {
                    // Rule 37.a of "Grafia Braille para a Língua Portuguesa"
                    simple = true;
                } else if (
                    !open &&
                    valueToCheck[valueToCheck.length - 1].match(/[0-9]/)
                ) {
                    // Rule 37.b of "Grafia Braille para a Língua Portuguesa"
                    simple = true;
                } else if (
                    open &&
                    containsUnitOfMeasurementPtBr(valueToCheck)
                ) {
                    // Rule 37.c of "Grafia Braille para a Língua Portuguesa"
                    simple = true;
                } else if (open && isRomanNumeral(valueToCheck)) {
                    // Rule 37.a of "Grafia Braille para a Língua Portuguesa"
                    simple = true;
                }
            }

            // transition chars to make recursion easily
            if (simple) {
                if (open) {
                    open = open === '(' ? '╘' : '╓';
                }
                close = close === ')' ? '╛' : '╖';
            } else {
                if (open) {
                    open = open === '(' ? '┌' : '╔';
                }
                close = close === ')' ? '┐' : '╗';
            }

            output += (open ?? '') + match[2] + close;
            lastIndex = end;
        }
        output += txt.slice(lastIndex);
        txt = output;
    } while (txt.match(regex));

    return txt
        .replaceAll('╘', config.parenthesisSimple[0])
        .replaceAll('╛', config.parenthesisSimple[1])
        .replaceAll('╓', config.bracketsSimple[0])
        .replaceAll('╖', config.bracketsSimple[1])
        .replaceAll('┌', config.parenthesisNormal[0])
        .replaceAll('┐', config.parenthesisNormal[1])
        .replaceAll('╔', config.bracketsNormal[0])
        .replaceAll('╗', config.bracketsNormal[1]);
}

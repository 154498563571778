import { breakWordToSyllables } from './BreakWordToSyllables';
import {
    BasicChar,
    getBrailleFromChar,
    MathContext,
    NumberChar,
    SymbolChar,
    toUnicode,
} from '../conversion/braille/CharMap';
import { isWordUnbreakable } from '../edit-document/DictionaryUnbreakable';
import { ConversionFlags } from '../conversion/braille/ConversionFlags';

const normalCharSet = new Set();
for (let char in {
    ...BasicChar,
    ...NumberChar,
    ...SymbolChar,
    ...MathContext,
}) {
    normalCharSet.add(
        toUnicode(BasicChar[char] ?? NumberChar[char] ?? SymbolChar[char]) ??
            MathContext[char],
    );
}

export const MathOperators = ['-', '+', '÷', '—', '×', '='];

export const MathOperatorsWithConvertedChars = [...MathOperators, '"', 'ÿ'];

export const BrailleMathOperators = MathOperators.map((symbol) => {
    return toUnicode(MathContext[symbol] ?? SymbolChar[symbol]);
});

/**
 * @type {Object<string, number>}
 */
export const BreakType = {
    NORMAL: 0,
    MATH: 1,
    URL: 2,
};

/**
 * @param brailleWord {string}
 * @param wordMap {WordMap}
 * @param distanceBetweenHyphens {number}
 * @returns {{word: string, brailleSyllables: string[], wordSyllables: string[], type: number}}
 */
export function breakBrailleWordToSyllables(
    brailleWord,
    wordMap,
    distanceBetweenHyphens,
) {
    if (isWordUnbreakable(wordMap.word.toLowerCase())) {
        // console.debug(`Word is unbreakable: ${wordMap.word}`);
        return {
            brailleSyllables: [brailleWord],
            word: wordMap.word,
            wordSyllables: [wordMap.word],
            type: BreakType.NORMAL,
        };
    }

    let brailleChunks;
    let wordChunks;
    /**
     * @type {number | null}
     */
    let type = null;
    if (wordMap.hasContextComputerRelated) {
        // break in chars: . - _ : / ? & =
        // the char / is  ┐ in computer related context
        // some braille chars are composites and conflict with other non-related chars, so, it necessarily writes by hand
        brailleChunks = brailleWord.split(/(?<=⠄|⠤|⠸[^⠴⠇⠽]|⠒|⠲|⠢|⠯|⠶|⠱)/g);
        wordChunks = wordMap.word.split(/(?<=[.\-_:┐?&=@])/g);
        type = BreakType.URL;
    }

    if (wordMap.hasContextMath) {
        brailleChunks = brailleWord.split(
            new RegExp(`(?<=[${BrailleMathOperators.join('')}])`, 'g'),
        );
        wordChunks = wordMap.word.split(
            new RegExp(
                `(?<=[${MathOperatorsWithConvertedChars.join('')}])`,
                'g',
            ),
        );
        type = BreakType.MATH;
    }

    if (type) {
        if (brailleChunks.length !== wordChunks.length) {
            console.warn(
                `Word and braille should have same amount of chunks: ${wordMap.word}`,
            );
            return {
                brailleSyllables: [brailleWord],
                word: wordMap.word,
                wordSyllables: [wordMap.word],
                type,
            };
        }
        return {
            brailleSyllables: brailleChunks,
            word: wordMap.word,
            wordSyllables: wordChunks,
            type,
        };
    }

    const HYPHEN_CHAR = toUnicode(SymbolChar['-']);

    let hyphenSplitBraille = brailleWord.split(HYPHEN_CHAR);
    let hyphenSplitWord = wordMap.word.split(/[-]/);

    let brailleSyllables = [];
    let wordSyllables = [];

    for (let [idx, word] of hyphenSplitWord.entries()) {
        const brailleWord = hyphenSplitBraille[idx];
        let broken = breakWordToSyllables(word).filter((chunk) => chunk.length);
        let brailleSyllablesChunk = [];
        let wordSyllablesChunk = [];

        wordSyllablesChunk = [...broken];

        let braille = [];
        let currentPiece = null;
        let currentIdx = null;
        for (let i = 0; i < brailleWord.length; i++) {
            const char = brailleWord.charAt(i);
            if (!normalCharSet.has(char)) {
                braille.push(char);
                continue;
            }
            if (!currentPiece || currentIdx >= currentPiece.length) {
                if (!broken.length) break;
                if (currentPiece) {
                    brailleSyllablesChunk.push(braille.join(''));
                    braille = [];
                }
                currentPiece = broken.shift();
                currentIdx = 0;
                if (!currentPiece.length) continue;
            }
            const braillePieceChar = currentPiece[currentIdx];
            const flags = [];
            const offset = wordSyllables.join('').length;
            if (wordMap.charMap[currentIdx + offset]?.contextMath)
                flags.push(ConversionFlags.CONTEXT_MATH);
            if (wordMap.charMap[currentIdx + offset]?.contextComputerRelated)
                flags.push(ConversionFlags.CONTEXT_COMPUTER_RELATED);

            const regularChar = currentPiece?.charAt(currentIdx);
            const brailleCharFromRegular = regularChar
                ? toUnicode(getBrailleFromChar(flags, regularChar))
                : null;
            if (brailleCharFromRegular && brailleCharFromRegular !== char) {
                braille.push(char);
                continue;
            }

            if (braillePieceChar) {
                braille.push(
                    toUnicode(getBrailleFromChar(flags, braillePieceChar)),
                );
                currentIdx++;
            }
        }

        if (braille.length) {
            brailleSyllablesChunk.push(braille.join(''));
        }
        brailleSyllables = [...brailleSyllables, ...brailleSyllablesChunk];
        wordSyllables = [...wordSyllables, ...wordSyllablesChunk];

        if (idx + 1 < hyphenSplitWord.length) {
            brailleSyllables = [...brailleSyllables, HYPHEN_CHAR];
            wordSyllables = [...wordSyllables, '-'];
        }
    }

    // merge hyphened minimal distance (forward)
    let hyphenIdx = wordSyllables.findIndex((value) => value === '-');
    while (hyphenIdx !== -1) {
        let forward = true;
        for (
            let i = hyphenIdx + 1;
            i < wordSyllables.length && wordSyllables[i + 1] !== '-';
            i++
        ) {
            let syllable = wordSyllables[i];
            if (syllable.length < distanceBetweenHyphens) {
                if (wordSyllables.length > i + 1) {
                    wordSyllables.splice(i, 2, syllable + wordSyllables[i + 1]); // merge the syllable with next
                    brailleSyllables.splice(
                        i,
                        2,
                        brailleSyllables[i] + brailleSyllables[i + 1],
                    ); // merge the syllable with next
                    forward = false; // do the loop again to check if it fits
                }
                break;
            }
        }
        if (forward) {
            hyphenIdx = wordSyllables.findIndex(
                (value, index) => value === '-' && index > hyphenIdx,
            );
        } else {
            hyphenIdx = wordSyllables.findIndex((value) => value === '-');
        }
    }
    // merge hyphened minimal distance (backward)
    hyphenIdx = wordSyllables.findLastIndex((value) => value === '-');
    while (hyphenIdx !== -1) {
        let backward = true;
        for (
            let i = hyphenIdx - 1;
            i >= 0 && wordSyllables[i - 1] !== '-';
            i--
        ) {
            let syllable = wordSyllables[i];
            if (syllable.length < distanceBetweenHyphens) {
                if (i - 1 >= 0) {
                    wordSyllables.splice(
                        i - 1,
                        2,
                        wordSyllables[i - 1] + syllable,
                    ); // merge the syllable with previous
                    brailleSyllables.splice(
                        i - 1,
                        2,
                        brailleSyllables[i - 1] + brailleSyllables[i],
                    ); // merge the syllable with previous
                    backward = false; // do the loop again to check if it fits
                }
                break;
            }
        }
        if (backward) {
            hyphenIdx = wordSyllables.findLastIndex(
                (value, index) => value === '-' && index < hyphenIdx,
            );
        } else {
            hyphenIdx = wordSyllables.findLastIndex((value) => value === '-');
        }
    }

    brailleSyllables = brailleSyllables.filter((syllable) => !!syllable);
    if (brailleSyllables.length !== wordSyllables.length) {
        console.warn(
            `Cannot precisely break syllables from word: "${wordMap.word}"`,
        );
        return {
            brailleSyllables: [brailleWord],
            word: wordMap.word,
            wordSyllables: [wordMap.word],
            type: BreakType.NORMAL,
        };
    }

    return {
        brailleSyllables,
        word: wordMap.word,
        wordSyllables,
        type: BreakType.NORMAL,
    };
}

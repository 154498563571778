import { cachePage, uncachePage } from '../Cache';
import { getPages } from '../PageManipulation';

/**
 * Source: https://sgm.codebit.com.br/manutencao/47456
 */

/**
 * @param page {HTMLElement}
 */
function getEmptyLineAtStart(page) {
    let emptyLines = 0;
    let walk = page.firstChild;
    while (walk?.tagName === 'BR') {
        emptyLines++;
        walk = walk.nextSibling;
    }
    return emptyLines;
}

/**
 * @param editor {EditorCustom}
 */
export function mustUpdateVersion3(editor) {
    let maxVersion = 0;
    const pages = getPages(editor);
    for (const page of pages) {
        const documentVersion = parseInt(
            page.getAttribute('data-document-version') ?? '0',
        );
        if (documentVersion === 3) {
            return false;
        }
        if (documentVersion > maxVersion) {
            maxVersion = documentVersion;
        }
        if (documentVersion >= 3) return false;
    }

    let pagesStartedWithEmptyLine = 0;
    let totalEmptyLines = 0;
    for (let page of pages) {
        const emptyLine = getEmptyLineAtStart(page);
        totalEmptyLines += emptyLine;
        if (emptyLine) {
            pagesStartedWithEmptyLine++;
        } else {
            break;
        }
    }
    const avg = Math.round(totalEmptyLines / pagesStartedWithEmptyLine);
    console.debug(
        `Total pages: ${pages.length}; Pages started with empty lines: ${pagesStartedWithEmptyLine}`,
    );
    console.debug(`Average of empty lines per page: ${avg}`);
    return pagesStartedWithEmptyLine > 3 && avg >= 3;
}

/**
 * @param editor {EditorCustom}
 */
export function updateDocumentVersion3(editor) {
    for (const page of getPages(editor)) {
        page.setAttribute('data-document-version', '3');
    }
}

/**
 *
 * @param editor
 */
export function removeEmptyLinesAtStart(editor) {
    for (const page of getPages(editor)) {
        const cached = page.getAttribute('cached') === 'true';
        if (cached) {
            uncachePage(editor, page);
        }
        let walk = page.firstChild;
        while (walk?.tagName === 'BR') {
            const br = walk;
            walk = walk.nextSibling;
            br.remove();
        }
        if (cached) {
            cachePage(editor, page);
        }
    }
}

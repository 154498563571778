import { addIcon } from './MenuModule';
import {
    ControlPainelMenuDictionary,
    ControlPainelMenuDictionary2x,
} from '../../../../components/images';

/**
 * @param editor {EditorCustom}
 * @param params {MenuModuleParams}
 */
export function createStructureMenu(editor, params) {
    addIcon(
        editor,
        'customEditorDictionary',
        ControlPainelMenuDictionary,
        ControlPainelMenuDictionary2x,
    );

    editor.ui.registry.addMenuItem('customDocumentSettings', {
        // I18N
        text: 'Propriedades do documento',
        onAction: params.documentSettingsAction,
    });
    editor.ui.registry.addMenuItem('customHyphenationSettings', {
        // I18N
        text: 'Hifenização',
        onAction: params.hyphenationSettingsAction,
    });
    if (params.isAdmin) {
        editor.ui.registry.addMenuItem('customDictionary', {
            // I18N
            text: 'Dicionário',
            onAction: params.dictionaryAction,
            icon: 'customEditorDictionary',
        });
    }
}

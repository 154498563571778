import { TextCaseEnum } from '../../ModifyTextCase';
import { removeEmptyLinesAtStart } from '../core/document-update/updatePageVersion3';
import {
    alignmentCenter,
    alignmentLeft,
    alignmentRight,
} from '../core/TextAlignment';
import { insertBlankPage, insertPageBreak } from '../core/PageManipulation';
import { insertEditorElementImage, insertRecoil } from '../core/EditorElements';
import { getInsertSymbolsAndSpecialCharactersSubmenuItems } from '../SymbolsAndSpecialCharacters';
import { modifyTextCase } from '../core/Formatting';

/**
 * @param editor {EditorCustom}
 * @param params {MenuModuleParams}
 */
export function createEditMenu(editor, params) {
    editor.addShortcut(
        'ctrl + shift + r',
        // I18N
        'Recuo',
        () => insertRecoil(editor),
    );
    editor.ui.registry.addMenuItem('customEditUndo', {
        // I18N
        text: 'Desfazer',
        shortcut: 'meta + z',
        icon: 'undo',
        onAction: function () {
            editor.execCommand('Undo');
        },
    });
    editor.ui.registry.addMenuItem('customEditRedo', {
        // I18N
        text: 'Refazer',
        shortcut: 'meta + shift + z',
        icon: 'redo',
        onAction: function () {
            editor.execCommand('Redo');
        },
    });
    editor.ui.registry.addMenuItem('customEditCopy', {
        // I18N
        text: 'Copiar',
        shortcut: 'meta + c',
        icon: 'copy',
        onAction: function () {
            editor.execCommand('Copy');
        },
    });
    editor.ui.registry.addMenuItem('customEditCut', {
        // I18N
        text: 'Recortar',
        shortcut: 'meta + x',
        icon: 'cut',
        onAction: function () {
            editor.execCommand('Cut');
        },
    });
    editor.ui.registry.addMenuItem('customEditPaste', {
        // I18N
        text: 'Colar',
        shortcut: 'meta + v',
        icon: 'paste',
        onAction: function () {
            editor.execCommand('Paste');
        },
    });
    editor.addShortcut(
        'ctrl + l',
        // I18N
        'Localizar e substituir',
        () => editor.execCommand('SearchReplace'),
    );
    editor.ui.registry.addMenuItem('customEditFindReplace', {
        // I18N
        text: 'Localizar e substituir',
        shortcut: 'ctrl + l',
        icon: 'search',
        onAction: function () {
            editor.execCommand('SearchReplace');
        },
    });
    editor.ui.registry.addNestedMenuItem('customCaseChange', {
        // I18N
        text: 'Uso de maiúsculas/minúsculas',
        getSubmenuItems: function () {
            return [
                {
                    type: 'menuitem',
                    // I18N
                    text: 'letras minúsculas',
                    onAction: () => {
                        modifyTextCase(editor, TextCaseEnum.LOWER_CASE);
                    },
                },
                {
                    type: 'menuitem',
                    // I18N
                    text: 'LETRAS MAIÚSCULAS',
                    onAction: () => {
                        modifyTextCase(editor, TextCaseEnum.UPPER_CASE);
                    },
                },
                {
                    type: 'menuitem',
                    // I18N
                    text: 'Todas As Palavras Com A Primeira Letra Maiúscula',
                    onAction: () => {
                        modifyTextCase(editor, TextCaseEnum.TITLE_CASE);
                    },
                },
            ];
        },
    });
    editor.ui.registry.addNestedMenuItem('customEditFormat', {
        // I18N
        text: 'Formatar',
        getSubmenuItems: function () {
            return [
                {
                    type: 'togglemenuitem',
                    // I18N
                    text: 'Negrito',
                    onAction: () => editor.execCommand('Bold'),
                    shortcut: 'meta + b',
                    icon: 'bold',
                    onSetup: (api) =>
                        api.setActive(editor.formatter.match('bold')),
                },
                {
                    type: 'togglemenuitem',
                    // I18N
                    text: 'Itálico',
                    onAction: () => editor.execCommand('Italic'),
                    shortcut: 'meta + i',
                    icon: 'italic',
                    onSetup: (api) =>
                        api.setActive(editor.formatter.match('italic')),
                },
                {
                    type: 'togglemenuitem',
                    // I18N
                    text: 'Sublinhado',
                    onAction: () => editor.execCommand('Underline'),
                    shortcut: 'meta + u',
                    icon: 'underline',
                    onSetup: (api) =>
                        api.setActive(editor.formatter.match('underline')),
                },
                { type: 'separator' },
                {
                    type: 'togglemenuitem',
                    // I18N
                    text: 'Sobrescrito',
                    onAction: () => editor.execCommand('Superscript'),
                    icon: 'superscript',
                    onSetup: (api) =>
                        api.setActive(editor.formatter.match('superscript')),
                },
                {
                    type: 'togglemenuitem',
                    // I18N
                    text: 'Subscrito',
                    onAction: () => editor.execCommand('Subscript'),
                    icon: 'subscript',
                    onSetup: (api) =>
                        api.setActive(editor.formatter.match('subscript')),
                },
                { type: 'separator' },
                {
                    type: 'menuitem',
                    // I18N
                    text: 'Remover linhas em branco do início de todas as páginas',
                    onAction: () => removeEmptyLinesAtStart(editor),
                },
            ];
        },
    });
    editor.ui.registry.addNestedMenuItem('customEditAlignment', {
        // I18N
        text: 'Alinhar',
        getSubmenuItems: function () {
            return [
                {
                    type: 'togglemenuitem',
                    // I18N
                    text: 'Esquerda',
                    onAction: () => alignmentLeft(editor),
                    icon: 'align-left',
                    onSetup: (api) =>
                        api.setActive(
                            !editor.formatter.closest([
                                'alignmentCenter',
                                'alignmentRight',
                            ]),
                        ),
                },
                {
                    type: 'togglemenuitem',
                    // I18N
                    text: 'Centro',
                    onAction: () => alignmentCenter(editor),
                    icon: 'align-center',
                    onSetup: (api) =>
                        api.setActive(
                            !!editor.formatter.closest(['alignmentCenter']),
                        ),
                },
                {
                    type: 'togglemenuitem',
                    // I18N
                    text: 'Direita',
                    onAction: () => alignmentRight(editor),
                    icon: 'align-right',
                    onSetup: (api) =>
                        api.setActive(
                            !!editor.formatter.closest(['alignmentRight']),
                        ),
                },
            ];
        },
    });
    editor.ui.registry.addNestedMenuItem('customEditContext', {
        // I18N
        text: 'Contexto',
        getSubmenuItems: function () {
            return [
                {
                    type: 'menuitem',
                    // I18N
                    text: 'Suprimir formatação',
                    onAction: () =>
                        editor.execCommand(
                            'mceToggleFormat',
                            true,
                            'suppression',
                        ),
                    icon: 'remove-formatting',
                },
                {
                    type: 'menuitem',
                    // I18N
                    text: 'Expressão matemática',
                    onAction: () =>
                        editor.execCommand('mceToggleFormat', true, 'math'),
                    icon: 'customEditorMath',
                },
                {
                    type: 'menuitem',
                    // I18N
                    text: 'Informático',
                    onAction: () =>
                        editor.execCommand(
                            'mceToggleFormat',
                            true,
                            'computerRelated',
                        ),
                    icon: 'link',
                },
                {
                    type: 'menuitem',
                    // I18N
                    text: 'Ficha catalográfica',
                    onAction: () =>
                        editor.execCommand('mceToggleFormat', true, 'catalog'),
                    icon: 'customCatalogSheet',
                },
            ];
        },
    });
    editor.ui.registry.addNestedMenuItem('customEditInsert', {
        // I18N
        text: 'Inserir',
        getSubmenuItems: function () {
            return [
                {
                    type: 'menuitem',
                    // I18N
                    text: 'Página em branco',
                    onAction: () => insertBlankPage(editor),
                    shortcut: 'ctrl + shift + enter',
                },
                {
                    type: 'menuitem',
                    // I18N
                    text: 'Quebra de página',
                    onAction: () => insertPageBreak(editor),
                    shortcut: 'ctrl + enter',
                },
                { type: 'separator' },
                {
                    type: 'menuitem',
                    // I18N
                    text: 'Referência de imagem',
                    icon: 'image',
                    onAction: () => insertEditorElementImage(editor),
                },
                {
                    type: 'menuitem',
                    // I18N
                    text: 'Recuo',
                    shortcut: 'ctrl + shift + r',
                    onAction: () => insertRecoil(editor),
                },
                {
                    // I18N
                    text: 'Símbolo ou caractere especial',
                    type: 'nestedmenuitem',
                    icon: 'customEditorCharMap',
                    getSubmenuItems: () => {
                        return getInsertSymbolsAndSpecialCharactersSubmenuItems(
                            editor,
                        );
                    },
                },
            ];
        },
    });
    editor.ui.registry.addMenuItem(
        'customEditRetrieveImagesDescriptionsBackground',
        {
            type: 'menuitem',
            text: 'Gerar descrições em segundo plano',
            icon: 'image',
            onAction: () => params.retrieveImagesDescriptionsBackgroundAction(),
        },
    );
}

import CloseModalButton from '../components/modal/CloseModalButton';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as DictionaryService from '../services/DictionaryService';
import DictionaryModalList from './DictionaryModalList';
import DictionaryModalForm from './DictionaryModalForm';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { useContext } from 'react';
import { SaveDictionaryValidationErrorEnumTxt } from 'plataforma-braille-common';
import './DictionaryModal.scss';
import PropTypes from 'prop-types';

/**
 * @typedef {object} FormData
 * @property {boolean | null | undefined} newWord
 * @property {string | null | undefined} name
 * @property {string | null | undefined} description
 */

/**
 * @typedef {object} FormError
 * @property {string | null | undefined} name
 * @property {string | null | undefined} description
 */

/**
 * @param show {boolean}
 * @param onClose {function}
 * @returns {JSX.Element}
 * @constructor
 */
function DictionaryModal({ show, onClose }) {
    const { setConfirmModal, setInfoModal, backendConnectionError } =
        useContext(EnvironmentContext);

    /**
     * @type {React.MutableRefObject<DictionaryModalListFunctions | null>}
     */
    const dictionaryModalListRef = useRef(null);
    /**
     * @type {FormData | null}
     */
    const formDataInitialValue = null;
    const [formData, setFormData] = useState(formDataInitialValue);
    const initialFormError = {};
    const [formError, setFormError] = useState(initialFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    /**
     * @type {string | null}
     */
    const selectedNameInitialValue = null;
    const [selectedName, setSelectedName] = useState(selectedNameInitialValue);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    /**
     * @param formData {FormData | null}
     */
    function updateFormData(formData) {
        if (!formData) {
            setFormData(null);
        } else {
            setFormData((prevState) => {
                if (
                    prevState?.newWord === true &&
                    formData?.newWord === false
                ) {
                    return null;
                }
                return { ...prevState, ...formData };
            });
        }
        if (formData?.newWord) {
            setSelectedName(null);
        }
    }

    /**
     * @param formData {FormData}
     * @return {{hasErrors: boolean, errors: FormError}}
     */
    function hasValidationErrorDocument(formData) {
        let errors = {};
        let hasErrors = false;

        if (!!formData && (!formData.name || formData.name.trim() === '')) {
            errors.name = 'Campo obrigatório.';
            hasErrors = true;
        }

        if (
            !!formData &&
            (!formData.description || formData.description.trim() === '')
        ) {
            errors.description = 'Campo obrigatório.';
            hasErrors = true;
        }

        return { hasErrors, errors };
    }

    // I18N
    const modalTitle = 'Dicionário';

    function hasValidationError(document = true) {
        const { hasErrors, errors } = hasValidationErrorDocument(formData);
        if (document) setValidateOnChange(true);
        setFormError(errors);
        return hasErrors;
    }

    async function fetchDictionary() {
        dictionaryModalListRef.current?.refresh();
    }

    async function save() {
        if (hasValidationError()) return;

        let title;
        const dictionary = {
            name: formData.name,
            description: formData.description,
        };

        setSaveLoading(true);
        try {
            let message;
            if (formData.newWord) {
                // I18N
                title = 'Nova palavra';
                // I18N
                message = 'Palavra adicionada com sucesso!';
                await DictionaryService.createDictionary(dictionary);
            } else {
                // I18N
                title = 'Editar palavra';
                // I18N
                message = 'Palavra atualizada com sucesso!';
                await DictionaryService.editDictionary(
                    selectedName,
                    dictionary,
                );
            }

            setInfoModal({
                title,
                message,
                show: true,
                onClose: () => {
                    fetchDictionary().then();
                    updateFormData(null);
                    setSelectedName(null);
                },
            });
        } catch (e) {
            backendConnectionError(
                'Fail to save word to dictionary',
                e,
                null,
                title,
                SaveDictionaryValidationErrorEnumTxt,
            );
        } finally {
            setSaveLoading(false);
        }
    }

    function remove() {
        setConfirmModal({
            // I18N
            title: 'Remover palavra',
            message: `Deseja realmente remover a palavra: "${selectedName}"? <strong>Essa operação não poderá ser desfeita</strong>.`,
            show: true,
            onConfirm: async () => {
                setRemoveLoading(true);
                try {
                    await DictionaryService.deleteDictionary(selectedName);
                    setInfoModal({
                        // I18N
                        title: 'Remover palavra',
                        // I18N
                        message: 'Palavra removida com sucesso!',
                        show: true,
                        onClose: () => {
                            fetchDictionary().then();
                            updateFormData(null);
                            setSelectedName(null);
                        },
                    });
                } catch (e) {
                    backendConnectionError(
                        'Fail to remove word from dictionary',
                        e,
                    );
                } finally {
                    setRemoveLoading(false);
                }
            },
        });
    }

    const documentForm = useMemo(() => {
        return (
            <DictionaryModalForm
                formError={formError}
                formData={formData}
                onSave={save}
                onRemove={remove}
                onFormUpdate={updateFormData}
                saveLoading={saveLoading}
                removeLoading={removeLoading}
            />
        );
    }, [formData, formError, saveLoading, removeLoading]);

    return (
        <div
            className={`modal default-modal dictionary-modal ${show ? 'show' : ''}`}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onClose} />
                <h2>{modalTitle}</h2>
                <div className={'gd-inner table-form-container'}>
                    <div className={'gd-col gd-col-5--desktop'}>
                        <div className={'list-container'}>
                            <DictionaryModalList
                                ref={dictionaryModalListRef}
                                selected={selectedName}
                                onSelected={(selected) => {
                                    setSelectedName(selected.name);
                                    setFormData({
                                        newWord: false,
                                        name: selected.name,
                                        description: selected.description,
                                    });
                                }}
                                showingModal={show}
                            />
                        </div>
                    </div>
                    <div className={'gd-col gd-col-5--desktop'}>
                        <div className={'form-container'}>{documentForm}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

DictionaryModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default DictionaryModal;

import {
    getBrailleDocument,
    isElementVisible,
    isInsidePage,
} from './core/EditorUtil';
import {
    isShowingNonPrintableChars,
    removeNonPrintableChars,
    showNonPrintableChars,
} from './core/ShowNonPrintableChars';
import { getBrailleView, getPages } from './core/PageManipulation';
import { cachePage, isPageCached, uncachePage } from './core/Cache';

/**
 * @param editor {EditorCustom}
 */
export function updateScroll(editor) {
    if (!editor) return;
    editor.custom?.scrollModule?.onScroll(null);
}

export class ScrollModule {
    /**
     * @type {number | null}
     */
    timerScroll = null;

    /**
     * @param editor {EditorCustom}
     */
    constructor(editor) {
        this.editor = editor;
    }

    /**
     * @param e {Event | null}
     */
    onScroll(e) {
        if (this.timerScroll) clearTimeout(this.timerScroll);
        const self = this;
        const coreModule = this.editor.custom.coreModule;

        this.timerScroll = setTimeout(() => {
            try {
                if (!self.editor.getDoc()) return;

                for (const page of getPages(self.editor)) {
                    const cached = isPageCached(page);
                    if (
                        isElementVisible(
                            self.editor.getBody().parentElement,
                            page,
                            self.editor.custom.zoom,
                        )
                    ) {
                        const brailleDocument = getBrailleDocument(self.editor);
                        if (cached === false) {
                            if (brailleDocument.convertedToBraille) {
                                coreModule.brailleView.showBrailleView(
                                    page,
                                    true,
                                );
                            }
                            continue;
                        }
                        uncachePage(self.editor, page, true);
                        if (brailleDocument.convertedToBraille) {
                            coreModule.brailleView.showBrailleView(page, true);
                        }
                        if (
                            self.editor.custom.isShowingNonPrintableChars &&
                            !isShowingNonPrintableChars(page)
                        ) {
                            showNonPrintableChars(self.editor, page);
                        }
                    } else {
                        if (cached === true) continue;
                        const { endContainer } = self.editor.selection.getRng();
                        if (isInsidePage(endContainer, page)) {
                            continue;
                        }
                        getBrailleView(page)?.remove();
                        if (self.editor.custom.isShowingNonPrintableChars) {
                            removeNonPrintableChars(page);
                        }
                        cachePage(self.editor, page, true);
                    }
                }
            } finally {
                self.timerScroll = null;
            }
            self.editor.fire('scrollFinished', e);
        }, 50);
    }

    install() {
        const onScrollFn = (e) => this.onScroll(e);
        this.editor.getDoc().addEventListener('scroll', onScrollFn);
        this.editor.on('documentDestroyed', () => {
            this.editor.getDoc().addEventListener('scroll', onScrollFn);
        });
    }
}

/**
 * @param page {HTMLElement | Node}
 */
export function updatePageVersion6(page) {
    const computerRelatedElements = page.querySelectorAll(
        'editor-element[type="informative"]',
    );
    for (let element of computerRelatedElements) {
        element.setAttribute('type', 'computer-related');
    }
}

/**
 * @enum {number}
 */
export const ConversionFlags = {
    HIGHLIGHT: 0,
    SUP: 1,
    SUB: 2,
    CONTEXT_COMPUTER_RELATED: 3,
    CONTEXT_MATH: 4,
    CONTEXT_CATALOG: 5,
    UPPER_CASE: 6,
};

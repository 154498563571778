import FieldCheckbox from '../components/FieldCheckbox';
import FieldText from '../components/FieldText';
import './DictionaryModalForm.scss';
import PropTypes from 'prop-types';

/**
 * @param formData {FormData | null}
 * @param formError {FormError}
 * @param onSave {function()}
 * @param onRemove {function()}
 * @param onFormUpdate {function(FormData | null)}
 * @param saveLoading {boolean}
 * @param removeLoading {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
function DictionaryModalForm({
    formData,
    formError,
    onSave,
    onRemove,
    onFormUpdate,
    saveLoading,
    removeLoading,
}) {
    const editing = !!formData;

    const clearForm = () => {
        onFormUpdate(null);
    };

    return (
        <>
            <form className={'condensed-controls'}>
                <label className={'field-label'}>
                    {/*I18N*/}
                    {'Formulário'}
                </label>
                <section className={'modal-form'}>
                    <div className={'form-itens'}>
                        <div className={'gd-inner'}>
                            <div className={'gd-col gd-col-5--desktop'}>
                                <FieldCheckbox
                                    inputs={(() => [
                                        {
                                            // I18N
                                            label: 'Nova palavra',
                                            checked: formData?.newWord ?? false,
                                            onChange: (e) => {
                                                const checked =
                                                    e.target.checked;
                                                onFormUpdate({
                                                    newWord: checked,
                                                    name: checked ? '' : '',
                                                    description: checked
                                                        ? ''
                                                        : '',
                                                });
                                            },
                                        },
                                    ])()}
                                />
                            </div>
                        </div>
                        <div className={'gd-inner'}>
                            <div className={'gd-col gd-col-5--desktop'}>
                                <div className={'gd-inner'}>
                                    <div
                                        className={
                                            'gd-col gd-col-2--desktop gd-col--valign-bottom'
                                        }
                                    >
                                        <FieldText
                                            // I18N
                                            label={'Palavra'}
                                            required={true}
                                            disabled={!editing}
                                            validationError={formError.name}
                                            value={formData?.name ?? ''}
                                            onChange={(e) =>
                                                onFormUpdate({
                                                    name: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div
                                        className={
                                            'gd-col gd-col-3--desktop gd-col--valign-bottom'
                                        }
                                    >
                                        <FieldText
                                            // I18N
                                            label={'Descrição'}
                                            required={true}
                                            disabled={!editing}
                                            validationError={
                                                formError.description
                                            }
                                            value={formData?.description ?? ''}
                                            onChange={(e) =>
                                                onFormUpdate({
                                                    description: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {editing && (
                    <div className={'gd-col--align-between controls'}>
                        <div>
                            <button
                                className={'button'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    clearForm();
                                }}
                            >
                                {'Cancelar'}
                            </button>
                            {formData && !formData.newWord && (
                                <button
                                    className={`button ${removeLoading ? 'loading' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onRemove();
                                    }}
                                >
                                    {'Remover'}
                                </button>
                            )}
                        </div>
                        <div>
                            <button
                                className={`button primary ${saveLoading ? 'loading' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onSave();
                                }}
                            >
                                {formData.newWord ? 'Cadastrar' : 'Editar'}
                            </button>
                        </div>
                    </div>
                )}
            </form>
        </>
    );
}

DictionaryModalForm.propTypes = {
    formData: PropTypes.shape({
        newWord: PropTypes.bool,
        name: PropTypes.string,
        description: PropTypes.string,
    }),
    formError: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
    }),
    onSave: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onFormUpdate: PropTypes.func.isRequired,
    saveLoading: PropTypes.bool,
    removeLoading: PropTypes.bool,
};

export default DictionaryModalForm;

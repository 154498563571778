import {
    generateId,
    getClosestEditorElement,
    getClosestElementAlignment,
    getPagesInvolved,
    getSelectedNodes,
    getSelectedParagraphs,
    isEditorElementRepresentationParagraphBreak,
    isInsideEditorElementImage,
    isInsideEditorElementImageLayout,
} from './EditorUtil';
import { getCaretPosition, setCaretPosition } from './CaretPath';
import { ZERO_WIDTH_NB_CHAR } from '../KeyboardModule';
import { addInvisibleParagraphBreak } from './EditorElements';

/**
 * @enum {string}
 */
export const AlignmentTypes = {
    CENTER: 'alignment-center',
    RIGHT: 'alignment-right',
    LEFT: 'alignment-left',
};

/**
 * @param editor {EditorCustom}
 * @param type {AlignmentTypes}
 */
export function changeAlignment(editor, type) {
    editor.undoManager.transact(() => {
        const node = editor.selection.getNode();
        const editorElement = getClosestEditorElement(node);
        if (
            isInsideEditorElementImage(editorElement) ||
            isInsideEditorElementImageLayout(editorElement)
        ) {
            editor.notificationManager.open({
                // I18N
                text: 'Não é possível alterar o alinhamento na seleção atual',
                type: 'warning',
                timeout: 5000,
            });
            return false;
        }
        if (getPagesInvolved(getSelectedNodes(editor)).length > 1) {
            editor.notificationManager.open({
                // I18N
                text: 'Não é possível aplicar alinhamento em mais de uma página',
                type: 'warning',
                timeout: 5000,
            });
            return false;
        }

        function appendNewAlignmentContainerInSelection() {
            let alignmentContainer = editor.dom.create('editor-element', {
                type,
            });
            const id = generateId(editor, 'editor-element-alignment');
            alignmentContainer.setAttribute('id', id);
            editor.selection.setContent(alignmentContainer.outerHTML);
            alignmentContainer = editor.dom.get(id);
            const textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
            alignmentContainer.appendChild(textNode);
            editor.selection.setCursorLocation(textNode, 0);
            alignmentContainer.removeAttribute('id');
            return alignmentContainer;
        }

        const selectedParagraphs = getSelectedParagraphs(editor);
        if (!selectedParagraphs.length) {
            const alignmentContainer = appendNewAlignmentContainerInSelection();
            if (alignmentContainer.nextElementSibling?.tagName === 'BR') {
                const br = alignmentContainer.nextElementSibling;
                br.style.display = 'none';
            }
            return;
        }
        const caretPosition = getCaretPosition(editor);
        for (let paragraph of selectedParagraphs) {
            if (type !== AlignmentTypes.LEFT) {
                if (!paragraph.length) {
                    continue;
                }
                removeAlignment(editor, paragraph);
                let alignmentContainer;
                alignmentContainer = editor.dom.create('editor-element', {
                    type,
                });
                const firstElement = paragraph[0];
                firstElement.parentElement.insertBefore(
                    alignmentContainer,
                    firstElement,
                );
                for (let element of paragraph) {
                    alignmentContainer.appendChild(element);
                }
                if (!alignmentContainer.innerText.trim().length) {
                    alignmentContainer.innerHTML = ZERO_WIDTH_NB_CHAR;
                }
                if (alignmentContainer.nextElementSibling?.tagName === 'BR') {
                    const br = alignmentContainer.nextElementSibling;
                    br.style.display = 'none';
                }
            } else {
                removeAlignment(editor, paragraph);
            }
        }
        if (caretPosition)
            setCaretPosition(
                editor,
                caretPosition?.contextElement,
                caretPosition.path,
            );
        editor.selection.collapse(true);
        /**
         * @type {PageDataChangedEvent}
         */
        const pageDataChangedEvent = {
            caretPosition: getCaretPosition(editor),
        };
        editor.fire('pageDataChanged', pageDataChangedEvent);
    });

    /**
     * @param editor {EditorCustom}
     * @param paragraph {Node[] | undefined}
     */
    function removeAlignment(editor, paragraph = null) {
        const alignment = getClosestElementAlignment(
            paragraph[0] ?? editor.selection.getNode(),
        );
        if (!alignment) return;
        const br = alignment?.nextElementSibling;
        if (br?.tagName === 'BR' && br?.style?.display === 'none') {
            // noinspection JSUnresolvedReference
            br.style?.removeProperty('display');
        }
        const caretPosition = getCaretPosition(editor);
        const childNodes = document.createDocumentFragment();
        for (let child of [...alignment.childNodes]) {
            if (isEditorElementRepresentationParagraphBreak(child)) continue;
            childNodes.appendChild(child);
        }
        alignment.replaceWith(childNodes);
        if (caretPosition)
            setCaretPosition(
                editor,
                caretPosition.contextElement,
                caretPosition.path,
            );
    }
}

/**
 * @param editor {EditorCustom}
 */
export function alignmentCenter(editor) {
    changeAlignment(editor, AlignmentTypes.CENTER);
}

/**
 * @param editor {EditorCustom}
 */
export function alignmentRight(editor) {
    changeAlignment(editor, AlignmentTypes.RIGHT);
}

/**
 * @param editor {EditorCustom}
 */
export function alignmentLeft(editor) {
    changeAlignment(editor, AlignmentTypes.LEFT);
}

/**
 * @param editor {EditorCustom}
 * @param page {HTMLElement | Node}
 */
export function prepareAlignments(editor, page) {
    let elements = [
        ...page.querySelectorAll('editor-element[type="alignment-center"]'),
        ...page.querySelectorAll('editor-element[type="alignment-right"]'),
    ];
    for (let element of elements) {
        if (
            !element.lastChild ||
            element.lastChild.nodeType !== Node.TEXT_NODE ||
            element.lastChild.textContent !== ZERO_WIDTH_NB_CHAR
        ) {
            const textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
            element.appendChild(textNode);
        }
        addInvisibleParagraphBreak(element);
    }
}

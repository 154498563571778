/**
 * @param page {HTMLElement | Node}
 */
export function updatePageVersion8(page) {
    const mathElements = page.querySelectorAll('editor-element[type="math"]');
    for (let element of mathElements) {
        let walk = element.previousSibling;
        let needsSpacePrior = false;
        let needsSpaceAfter = false;
        while (walk && walk.tagName !== 'BR') {
            if (walk.textContent !== '') {
                needsSpacePrior = true;
                break;
            }
            if (walk.previousSibling) {
                walk = walk.previousSibling;
            } else {
                walk = walk.parentElement;
            }
        }

        while (walk && walk.tagName !== 'BR') {
            if (walk.textContent !== '') {
                needsSpaceAfter = true;
                break;
            }
            if (walk.nextSibling) {
                walk = walk.nextSibling;
            } else {
                walk = walk.parentElement;
            }
        }
        if (needsSpacePrior) {
            const textNode = document.createTextNode(' ');
            element.replaceWith(textNode, element);
        }
        if (needsSpaceAfter) {
            const textNode = document.createTextNode(' ');
            element.replaceWith(element, textNode);
        }
    }
}

/**
 * Source: https://sgm.codebit.com.br/manutencao/46402
 * @param page {HTMLElement | Node}
 */
export function updatePageVersion2(page) {
    /**
     * @param node {Node}
     */
    function recursively(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            node.textContent = node.textContent.replaceAll('  ', '\u00A0 ');
        } else {
            for (let child of node.childNodes) {
                recursively(child);
            }
        }
    }
    recursively(page);
}

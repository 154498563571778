import { RoleEnum } from 'plataforma-braille-common';

const menuBar = ['customFile', 'customEdit', 'customView', 'customStructure'];

const toolbar =
    'customHome customHomeFavorite undo redo ' +
    '| bold italic underline ' +
    '| superscript subscript ' +
    '| customEditorAlignLeft customEditorAlignCenter customEditorAlignRight ' +
    '| customEditorImageReference customEditorInsertSymbolsAndSpecialCharacters' +
    '| customEditorSuppression customEditorMath customEditorComputerRelated customEditorCatalog';
const toolbarReadOnly = 'customHome';

function getFileMenuItems() {
    const items = [
        'customFileMenuImportPdf',
        'customFileMenuSave',
        'customFileMenuRemove',
        'customFileMenuRename',
        'customUnlockDocument',
        '|',
        'customFileMenuCloseStep',
        'customFileMenuSendTo',
        '|',
        'customFileMenuUserWithAccess',
        '|',
        'customFileMenuExport',
        '|',
        'customFileVersion',
        '|',
        'customFileMenuExit',
    ];
    return items.join(' ');
}

function getEditMenuItems() {
    const items = [
        'customEditInsert',
        'customEditFormat',
        'customEditAlignment',
        'customEditContext',
        'customCaseChange',
        '|',
        'customEditUndo',
        'customEditRedo',
        '|',
        'customEditCopy',
        'customEditCut',
        'customEditPaste',
        '|',
        'customEditRetrieveImagesDescriptionsBackground',
        '|',
        'customEditFindReplace',
    ];
    return items.join(' ');
}

function getStructureMenuItems() {
    const items = [
        'customDocumentSettings',
        'customHyphenationSettings',
        'customDictionary',
    ];
    return items.join(' ');
}

function getViewMenuItems() {
    const items = ['customViewNonPrintableChars', 'customViewAlternateFont'];
    return items.join(' ');
}

/**
 * @return {object}
 */
export function getTinyMceMenu() {
    return {
        customFile: {
            // I18N
            title: 'Arquivo',
            items: getFileMenuItems(),
        },
        customEdit: {
            // I18N
            title: 'Editar',
            items: getEditMenuItems(),
        },
        customView: {
            // I18N
            title: 'Ver',
            items: getViewMenuItems(),
        },
        customStructure: {
            // I18N
            title: 'Estrutura',
            items: getStructureMenuItems(),
        },
    };
}

/**
 * @param readyOnly {boolean}
 * @param userDocumentRoles {RoleEnum[]}
 * @return {string}
 */
export function getTinyMceToolbar(readyOnly, userDocumentRoles) {
    return readyOnly ||
        // users may have another roles in same document
        (userDocumentRoles.includes(RoleEnum.DESCRIPTION) &&
            userDocumentRoles.length === 1)
        ? toolbarReadOnly
        : toolbar;
}

/**
 * @return {string}
 */
export function getTinyMceMenuBar() {
    return menuBar.join(' ');
}

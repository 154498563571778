import {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
} from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { extractText, loadDocumentFromFile } from '../conversion/pdf/ImportPdf';
import PropTypes from 'prop-types';
import { delay } from '../util/Delay';
import { isDocumentTypeImageDescription } from 'plataforma-braille-common';

/**
 * @param file {File}
 * @param onProgress {function(currentPage: number, pageCount: number, abort: function()) | undefined}
 * @param abortFn {function():boolean | undefined}
 * @param documentType {import('plataforma-braille-common').DocumentTypeEnumValue | undefined}
 * @return {Promise<null | string>}
 */
export async function openPdf(
    file,
    onProgress = null,
    abortFn = null,
    documentType = null,
) {
    const reader = new FileReader();
    const document = await new Promise((resolve, reject) => {
        reader.onerror = async (e) => {
            reject(e);
        };
        reader.onload = async (e) => {
            try {
                resolve(await loadDocumentFromFile(e.target.result));
            } catch (e) {
                reject(e);
            }
        };
        reader.readAsArrayBuffer(file);
    });
    return await extractText(document, onProgress, abortFn, documentType);
}

/**
 * @typedef {object} ImportPdfParams
 * @property {function(boolean) | null | undefined} onLoading
 * @property {function({ fileName: string, html: string }) | null | undefined} onPdfLoaded
 * @property {function(Error, function()) | null | undefined} onError
 * @property {function(currentPage: number, pageCount: number, abort: function()) | null | undefined} onProgress
 * @property {import('plataforma-braille-common').DocumentTypeEnumValue} documentType
 */

/**
 * @typedef {object} ImportPdfFunctions
 * @property {function()} openFile
 * @property {function(triggerOnLoading: boolean | null | undefined): Promise<void>} abort
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<ImportPdfParams>>}
 */
const ImportPdf = forwardRef(
    ({ onLoading, onPdfLoaded, onError, onProgress, documentType }, ref) => {
        /**
         * @type {React.MutableRefObject<HTMLInputElement | null>}
         */
        const inputFileRef = useRef(null);
        const abortRef = useRef(false);
        const onLoadingRef = useRef(false);
        const documentTypeRef = useRef(documentType);
        const { setInfoModal } = useContext(EnvironmentContext);

        /**
         * @param triggerOnLoading {boolean | undefined}
         * @return {Promise<void>}
         */
        async function abort(triggerOnLoading = true) {
            if (triggerOnLoading) {
                if (onLoading) onLoading(false);
            }
            abortRef.current = true;
            do {
                await delay(500);
            } while (onLoadingRef.current);
            abortRef.current = false;
        }

        useImperativeHandle(ref, () => ({
            openFile: () => {
                abortRef.current = false;
                inputFileRef.current.value = null;
                inputFileRef.current.click();
            },
            abort,
        }));

        async function reimportPdf() {
            await abort(false);
            await fileSelected();
        }

        useEffect(() => {
            const before = isDocumentTypeImageDescription(
                documentTypeRef.current,
            );
            const after = isDocumentTypeImageDescription(documentType);

            if (before !== after) {
                documentTypeRef.current = documentType;
                if (inputFileRef.current.value) {
                    console.debug(
                        'Import PDF parameters changed. File will be reimported...',
                    );
                    reimportPdf().then();
                }
            }
        }, [documentType]);

        async function fileSelected() {
            try {
                onLoadingRef.current = true;
                if (onLoading) onLoading(true);
                let file = inputFileRef.current.files[0];
                const html = await openPdf(
                    file,
                    onProgress,
                    () => abortRef.current,
                    documentType,
                );
                if (html !== null) {
                    if (onPdfLoaded)
                        onPdfLoaded({ fileName: file.name, html: html });
                } else {
                    console.debug('PDF importing aborted.');
                }
            } catch (e) {
                console.error(e);
                if (onError)
                    onError(e, () => {
                        let message;
                        if (e.name === 'InvalidPDFException') {
                            // I18N
                            message =
                                'O arquivo selecionado está corrompido ou não é um PDF válido.';
                        } else {
                            // I18N
                            message =
                                'Erro ao importar o arquivo selecionado. Consulte o console para mais informações.';
                        }
                        setInfoModal({
                            title: 'Importar PDF',
                            message,
                            show: true,
                        });
                    });
            } finally {
                if (!abortRef.current) {
                    if (onLoading) onLoading(false);
                }
                onLoadingRef.current = false;
            }
        }

        return (
            <input
                style={{ display: 'none' }}
                accept={'.pdf'}
                type={'file'}
                ref={inputFileRef}
                onChange={fileSelected}
            />
        );
    },
);

ImportPdf.displayName = 'ImportPdf';

ImportPdf.propTypes = {
    onLoading: PropTypes.func,
    onPdfLoaded: PropTypes.func,
    onError: PropTypes.func,
    onProgress: PropTypes.func,
    onlyImages: PropTypes.bool,
};

export default ImportPdf;

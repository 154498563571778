import { InkFontTypeEnum } from 'plataforma-braille-common';
import {
    getBrailleDocument,
    getParagraphNodesUntilNode,
    getParagraphTextUntilNode,
    isInsideEditorElementImageLayout,
    pageHasText,
    removeParagraphBreaks,
    replaceNodeEdgeSpacesWithNbsp,
} from './EditorUtil';
import { CORE_LOGGER_ID, isDebugEnabled } from './CoreModule';
import { applyFormatting, removeFormatting } from './Formatting';
import {
    getLineCount,
    INNER_CONTEXT_CONTAINER_CLASSES,
    isInnerContextElement,
    scanCaretPath,
} from './CaretPath';
import {
    createNewPage,
    fixPageTermination,
    getBrailleView,
    getPageTermination,
    removeEmptyPage,
    replacePageContents,
} from './PageManipulation';
import { uncachePage } from './Cache';
import { convertPageToBraille } from '../../../../conversion/braille/HtmlToBraille';
import { BasicChar, toUnicode } from '../../../../conversion/braille/CharMap';

/**
 * @param page {HTMLElement}
 * @param brailleView {HTMLElement | null}
 */
export function appendBrailleView(page, brailleView) {
    if (brailleView) {
        page.append(brailleView);
    }
}

export class BrailleView {
    debug(...data) {
        if (isDebugEnabled()) {
            console.debug(CORE_LOGGER_ID, ...data);
        }
    }

    warn(...data) {
        console.warn(CORE_LOGGER_ID, ...data);
    }

    error(...data) {
        console.error(CORE_LOGGER_ID, ...data);
    }

    /**
     * @param brailleRows {string[]}
     * @param brailleGrid {HTMLElement}
     * @param brailleCellColCount {number}
     * @param brailleCellRowCount {number}
     */
    showBrailleInGrid(
        brailleRows,
        brailleGrid,
        { brailleCellColCount, brailleCellRowCount },
    ) {
        brailleGrid.innerHTML = '';
        for (let [rowIdx, row] of brailleRows.entries()) {
            if (rowIdx >= brailleCellRowCount) {
                this.warn(
                    `Braille roll do not fit in grid. Max expected rows: ${brailleCellRowCount}, found: ${brailleRows.length}`,
                );
                break;
            }
            let lastCellIdx = -1;
            for (let cellIdx = 0; cellIdx < row.length; cellIdx++) {
                lastCellIdx = cellIdx;
                const cell = row[cellIdx];
                if (cellIdx >= brailleCellColCount) {
                    this.warn(
                        `Braille cell do not fit in grid. Max expected columns: ${brailleCellColCount}, found: ${row.length}`,
                    );
                    break;
                }
                const cellContainer = document.createElement('div');
                cellContainer.innerText = cell;
                brailleGrid.appendChild(cellContainer);
            }
            for (
                lastCellIdx++;
                lastCellIdx < brailleCellColCount;
                lastCellIdx++
            ) {
                const cellContainer = document.createElement('div');
                cellContainer.innerText = toUnicode(BasicChar[' ']);
                brailleGrid.appendChild(cellContainer);
            }
        }
    }

    /**
     * @param braille {string[]}
     * @param brailleView {HTMLElement}
     * @param brailleDocument {BrailleDocument}
     */
    updateBrailleView(braille, brailleView, brailleDocument) {
        const {
            brailleCellRowCount,
            brailleCellColCount,
            braillePageMarginLeft,
            braillePageMarginRight,
            braillePageMarginTop,
            braillePageMarginBottom,
            pageHeight,
            pageWidth,
            pageMeasure,
        } = brailleDocument;

        let pageMeasureStr = pageMeasure?.toLowerCase();

        const braillePage = brailleView?.querySelector('editor-braille-page');
        const brailleGrid = brailleView?.querySelector('editor-braille-grid');
        braillePage.style.paddingLeft = `${braillePageMarginLeft}mm`;
        braillePage.style.paddingRight = `${braillePageMarginRight}mm`;
        braillePage.style.paddingTop = `${braillePageMarginTop}mm`;
        braillePage.style.paddingBottom = `${braillePageMarginBottom}mm`;
        braillePage.style.height = `${pageHeight}${pageMeasureStr}`;
        braillePage.style.marginTop = `-${pageHeight}${pageMeasureStr}`;
        braillePage.style.width = `${pageWidth}${pageMeasureStr}`;

        brailleGrid.style.gridTemplateRows = `repeat(${brailleCellRowCount}, 1fr)`;
        brailleGrid.style.gridTemplateColumns = `repeat(${brailleCellColCount}, 1fr)`;

        this.showBrailleInGrid(braille, brailleGrid, {
            brailleCellColCount,
            brailleCellRowCount,
        });
    }

    /**
     * This prepares the layout presentation to breaks, there is an exception in ink (fewer chars)
     * and should be prepared before line break (and after to restore desired state)
     * @param page {HTMLElement | DocumentFragment}
     * @param prepareToSearch {boolean}
     */
    prepareEditorElementImageLayoutToBreak(page, prepareToSearch) {
        const source = prepareToSearch
            ? [
                  ['[', '_`['],
                  [']', '_`]'],
              ]
            : [
                  ['_`[', '['],
                  ['_`]', ']'],
              ];

        const editorElements = page.querySelectorAll(
            'editor-element[type="image-layout"]',
        );
        for (let imageLayout of editorElements) {
            let walk = imageLayout.firstChild;
            while (walk) {
                if (walk.nodeType === Node.TEXT_NODE) {
                    walk.textContent = walk.textContent
                        .replaceAll(source[0][0], source[0][1])
                        .replaceAll(source[1][0], source[1][1]);
                }
                walk = walk.nextSibling;
            }
        }
    }

    /**
     * @param node {Node}
     * @param change {Break} }
     */
    breakLine(node, change) {
        if (change.searchText == null) return;

        const insideImageLayout = isInsideEditorElementImageLayout(node);

        // get the nodes prior text data prior the current node to calculate the number of occurrences
        let paragraphUntilNode = getParagraphTextUntilNode(node);
        let occurrences = 0;
        let idx = -1;
        while (
            (idx = paragraphUntilNode.indexOf(change.searchText, idx + 1)) !==
            -1
        ) {
            occurrences++;
        }

        // found the index of the break
        let paragraph = node.textContent;
        idx = -1;
        while ((idx = paragraph.indexOf(change.searchText, idx + 1)) !== -1) {
            occurrences++;
            if (occurrences === change.occurrences) break;
        }

        // check if the break is found (and fail otherwise)
        if (idx === -1 || occurrences !== change.occurrences) {
            this.error(
                `Could not determine the break point. Details: ${JSON.stringify(
                    {
                        change,
                        paragraphUntilNode,
                        paragraph,
                    },
                )}`,
            );
        }

        let useSymbol = change.useSymbol;
        if (useSymbol && paragraph.charAt(idx) === '-') {
            // breaking at hyphen
            useSymbol = false;
            idx++;
        } else if (useSymbol && paragraph.charAt(idx - 1) === '-') {
            // breaking after hyphen
            useSymbol = false;
        }

        const clone = node.cloneNode(true);
        node.textContent = node.textContent.substring(0, idx);
        clone.textContent = clone.textContent.substring(idx);
        if (clone.textContent === '') return;
        let type;
        if (useSymbol) {
            if (insideImageLayout) {
                type = 'paragraph-break-hyphen-2sp';
            } else {
                type = 'paragraph-break-hyphen';
            }
        } else {
            if (change.mathBreak) {
                type = 'paragraph-break-math';
            } else {
                if (insideImageLayout) {
                    type = 'paragraph-break-2sp';
                } else {
                    type = 'paragraph-break';
                }
            }
        }

        const breakParagraph = document.createElement('editor-element');
        breakParagraph.setAttribute('type', type);
        if (change.operator) {
            breakParagraph.setAttribute('data-operator', change.operator);
        }
        breakParagraph.setAttribute('data-break', JSON.stringify(change));
        replaceNodeEdgeSpacesWithNbsp(node);
        replaceNodeEdgeSpacesWithNbsp(clone);
        // noinspection JSCheckFunctionSignatures
        node.replaceWith(node, breakParagraph, clone);
    }

    /**
     * @typedef {object} Break
     * @property {number} wordIdx
     * @property {number | null} wordOffset
     * @property {string} searchText
     * @property {number} occurrences
     * @property {boolean} useSymbol
     * @property {boolean} mathBreak
     * @property {string | null} operator
     */

    /**
     * @typedef {object} ParagraphChange
     * @property {number} paragraph
     * @property {Break[]} breaks
     */

    /**
     * @param page {HTMLElement}
     * @param paragraphsChanges {ParagraphChange[]}
     */
    backPropagateChanges(page, paragraphsChanges) {
        if (!page) return;
        const { fragment, formatting } = removeFormatting(page);

        let elements = fragment.querySelectorAll(
            'editor-element[type="image"]',
        );
        for (let editorElement of elements) {
            editorElement.style.removeProperty('height'); // this could be removed in future
        }

        this.prepareEditorElementImageLayoutToBreak(fragment, true);

        elements = [
            ...fragment.querySelectorAll('editor-element[type="image-layout"]'),
            ...fragment.querySelectorAll('editor-element[type="summary"]'),
            ...fragment.querySelectorAll('editor-element[type="nth-root"]'),
            ...fragment.querySelectorAll('editor-element[type="line-segment"]'),
            ...fragment.querySelectorAll('editor-element[type="angle"]'),
        ];
        // this make the behavior uniform with the page
        for (let element of elements) {
            removeParagraphBreaks(element);
            for (const containerClass of INNER_CONTEXT_CONTAINER_CLASSES) {
                const container = element.querySelector(containerClass);
                if (!container) continue;
                const { fragment, formatting } = removeFormatting(
                    container,
                    true,
                );
                applyFormatting(fragment, formatting);
                container.innerHTML = '';
                container.appendChild(fragment);
            }
        }

        this.breakParagraphs(fragment, paragraphsChanges);

        applyFormatting(fragment, formatting);
        this.prepareEditorElementImageLayoutToBreak(fragment, false);

        replacePageContents(page, fragment);
    }

    /**
     * @param element {Node | DocumentFragment | HTMLElement}
     * @param paragraphChanges {ParagraphChange[]}
     */
    breakParagraphs(element, paragraphChanges) {
        const alreadyAdjusted = new Set();
        for (const {
            paragraph: currentParagraph,
            breaks,
        } of paragraphChanges) {
            for (let brk of breaks) {
                // computer related exception
                if (brk.searchText === '┐') {
                    brk.searchText = '/';
                }
                let found = false;
                let lastParagraph = null;
                let lastParagraphStartIdx = null;
                scanCaretPath(
                    element,
                    null,
                    null,
                    (path, paragraph, word, node) => {
                        if (lastParagraph !== paragraph) {
                            lastParagraph = paragraph;
                            lastParagraphStartIdx = path.length - 1;
                        }
                        let occurrences = 0;
                        const key = `${paragraph}:${brk.searchText}:${brk.occurrences}`;
                        if (
                            currentParagraph === paragraph &&
                            !alreadyAdjusted.has(key)
                        ) {
                            for (
                                let i = path.length - 1;
                                i >= lastParagraphStartIdx;
                                i--
                            ) {
                                // found the end of paragraph
                                // inner context is computed as same paragraph regardless paragraphs breaks inside
                                if (
                                    !isInnerContextElement(node, true) &&
                                    path[i] === '\n'
                                )
                                    break;
                                if (path[i] === brk.searchText) {
                                    occurrences++;
                                }
                            }
                            if (occurrences >= brk.occurrences) {
                                found = true;
                                alreadyAdjusted.add(key);
                                this.breakLine(node, brk);
                                return false;
                            }
                        }
                        return true;
                    },
                    true,
                );
                if (!found) {
                    this.warn(
                        `Could not found occurrence to break line. Details: ${JSON.stringify(brk)}`,
                    );
                }
            }
        }
    }

    /**
     * @param page {HTMLElement}
     * @param surplus {DocumentFragment}
     */
    appendSurplusNextPage(page, surplus) {
        let nextPage = page.nextSibling;
        if (!nextPage) {
            nextPage = createNewPage(this.editor);
            this.editor.dom.getRoot().appendChild(nextPage);
        } else {
            uncachePage(this.editor, nextPage);
        }
        if (nextPage.childNodes.length) {
            nextPage.insertBefore(surplus, nextPage.firstChild);
        } else {
            nextPage.appendChild(surplus);
        }
        const self = this;
        setTimeout(() => {
            self.showBrailleView(page, true);
        }, 0);
        this.updatePage(nextPage, false);
        removeEmptyPage(this.editor, nextPage);
        if (nextPage.parentElement) {
            fixPageTermination(this.editor, nextPage);
        }
    }

    /**
     * @param page {HTMLElement}
     * @param brailleCellRowCount {number}
     * @returns { DocumentFragment }
     */
    removeSurplusLines(page, brailleCellRowCount) {
        const surplus = document.createDocumentFragment();
        let paragraphUntilFirstNode = null;
        let lastLineCount = 0;
        const termination = getPageTermination(page);
        const self = this;
        getLineCount(page, (lineCount, node) => {
            if (termination === node) {
                // end of page reached
                return true;
            }
            const nodeLineCount = lineCount - lastLineCount;
            if (isInnerContextElement(node, true)) return true;
            if (lineCount >= brailleCellRowCount) {
                if (!surplus.firstChild) {
                    paragraphUntilFirstNode = getParagraphNodesUntilNode(node);
                }
                // this avoids deadlock
                if (nodeLineCount < brailleCellRowCount) {
                    surplus.appendChild(node);
                } else {
                    self.warn('Element is too big to fit a page', node);
                }
            }
            lastLineCount = lineCount;
        });
        if (
            paragraphUntilFirstNode?.length &&
            page.firstChild !== paragraphUntilFirstNode[0]
        ) {
            // move entire paragraph if it not contains all page
            for (let i = paragraphUntilFirstNode.length - 1; i >= 0; i--) {
                surplus.insertBefore(
                    paragraphUntilFirstNode[i],
                    surplus.firstChild,
                );
            }
        }
        return surplus;
    }

    /**
     * @param page {HTMLElement | Node}
     * @param massiveSurplus {boolean | null}
     */
    updatePage(page, massiveSurplus) {
        const brailleDocument = getBrailleDocument(this.editor);
        const {
            brailleCellRowCount,
            inkPageMarginTop,
            inkPageMarginRight,
            inkPageMarginLeft,
            inkPageMarginBottom,
            pageHeight,
            pageWidth,
            pageMeasure,
            inkFontType,
            inkPageLineHeight,
            inkFontSize,
        } = brailleDocument;

        let pageMeasureStr = pageMeasure?.toLowerCase();

        const brailleView = getBrailleView(page);

        page.style.removeProperty('margin-right');
        page.style.removeProperty('min-height');
        page.style.removeProperty('font-family');
        page.style.removeProperty('line-height');
        page.style.paddingLeft = `${inkPageMarginLeft}mm`;
        page.style.paddingRight = `${inkPageMarginRight}mm`;
        page.style.paddingTop = `${inkPageMarginTop}mm`;
        page.style.paddingBottom = `${inkPageMarginBottom}mm`;
        page.style.height = `${pageHeight}${pageMeasureStr}`;
        page.style.width = `${pageWidth}${pageMeasureStr}`;
        page.style.lineHeight = `${inkPageLineHeight}px`;
        const pageMargin = '30px'; // comes from css
        page.style.marginRight = `calc(${pageWidth}${pageMeasureStr} + ${pageMargin} * 2)`;
        let fontWeight;
        switch (inkFontType) {
            default:
                fontWeight = 'normal';
                break;
            case InkFontTypeEnum.DEJAVU_SANS_BOLD:
                fontWeight = 'bold';
                break;
        }
        page.style.fontSize = `${inkFontSize}px`;
        page.style.fontWeight = fontWeight;

        const { brailleGrid: braille, paragraphChanges } = convertPageToBraille(
            page,
            brailleDocument,
        );

        this.backPropagateChanges(page, paragraphChanges);

        // braille view is removed in back propagation
        appendBrailleView(page, brailleView);

        if (massiveSurplus !== null) {
            let surplusLines = this.removeSurplusLines(
                page,
                brailleCellRowCount,
            );
            if (surplusLines.childNodes.length) {
                if (massiveSurplus) {
                    const newPagesFragment = document.createDocumentFragment();
                    let pageReference = page;
                    while (surplusLines.firstChild) {
                        let newPageNodes = [];
                        getLineCount(surplusLines, (lineCount, node) => {
                            if (isInnerContextElement(node, true)) {
                                return true;
                            }
                            if (lineCount < brailleCellRowCount) {
                                newPageNodes.push(node);
                            } else {
                                if (surplusLines.firstChild === node) {
                                    // it's not possible fit the element, put in a new page and pray
                                    this.warn(
                                        'Element is too big to fit a page',
                                        node,
                                    );
                                    surplusLines.firstChild.remove();
                                    const newPage = createNewPage(this.editor);
                                    newPage.appendChild(node);
                                    page.replaceWith(page, newPage);
                                    pageReference = newPage;
                                    const self = this;
                                    setTimeout(() => {
                                        self.updatePage(newPage, false);
                                    }, 0);
                                }
                                return false;
                            }
                        });
                        if (newPageNodes.length) {
                            const newPage = createNewPage(this.editor);
                            for (let child of newPageNodes)
                                newPage.appendChild(child);
                            newPagesFragment.append(newPage);
                            this.updatePage(newPage, massiveSurplus);
                            while (
                                page.lastElementChild?.tagName === 'BR' &&
                                page.lastElementChild?.style?.display !== 'none'
                            ) {
                                page.lastElementChild.remove();
                            }
                            if (!pageHasText(newPage)) {
                                newPage.remove();
                            }
                        }
                    }
                    if (newPagesFragment.childNodes.length) {
                        pageReference.replaceWith(
                            pageReference,
                            newPagesFragment,
                        );
                    }
                } else {
                    this.appendSurplusNextPage(page, surplusLines);
                }
            }
        }

        if (brailleView)
            this.updateBrailleView(braille, brailleView, brailleDocument);
    }

    /**
     * @param page {HTMLElement | null}
     * @param update {boolean}
     */
    showBrailleView(page, update = false) {
        let brailleView = getBrailleView(page);
        if (brailleView && !update) {
            return;
        }
        brailleView =
            brailleView ?? this.editor.dom.create('editor-braille-view');
        brailleView.setAttribute('contentEditable', 'false');
        const braillePage = this.editor.dom.create('editor-braille-page');
        const brailleGrid = this.editor.dom.create('editor-braille-grid');
        brailleView.appendChild(braillePage);
        braillePage.appendChild(brailleGrid);
        appendBrailleView(page, brailleView);
        const brailleDocument = getBrailleDocument(this.editor);
        const {
            brailleCellRowCount,
            brailleCellColCount,
            hyphenation,
            hyphenationLettersMin,
            hyphenationParagraphMax,
            hyphenationSyllablesMin,
            hyphenationDistanceBetweenHyphens,
        } = brailleDocument;

        const { brailleGrid: braille } = convertPageToBraille(page, {
            brailleCellColCount,
            brailleCellRowCount,
            hyphenation,
            hyphenationLettersMin,
            hyphenationParagraphMax,
            hyphenationSyllablesMin,
            hyphenationDistanceBetweenHyphens,
        });
        this.updateBrailleView(braille, brailleView, brailleDocument);
    }

    /**
     * @param editor {EditorCustom}
     */
    constructor(editor) {
        this.editor = editor;
    }
}

import {
    getClosestElementAlignment,
    isEditorElementImage,
    isEditorElementImageLayout,
    isEditorElementRepresentationNbsp,
    isEditorElementRepresentationSpace,
    isElementVisible,
    isInsideAlignmentCenter,
    isInsideAlignmentRight,
    isInsideEditorElementImage,
    isInsideEditorElementImageLayout,
    isInvisibleSpace,
} from './EditorUtil';
import { getCaretPosition, setCaretPosition } from './CaretPath';
import { ZERO_WIDTH_NB_CHAR, ZERO_WIDTH_SPACE_CHAR } from '../KeyboardModule';
import { isEditorBrailleView } from '../../../../conversion/braille/HtmlToBraille';

export function toggleNonPrintableChars(editor) {
    const caretPosition = getCaretPosition(editor);
    const pages = editor.dom.select('editor-page');
    let result;
    if (isShowingNonPrintableChars(editor.getDoc())) {
        for (let page of pages) {
            if (isShowingNonPrintableChars(page)) {
                removeNonPrintableChars(page);
            }
        }
        result = false;
    } else {
        for (let page of pages) {
            if (isElementVisible(editor.getDoc().firstElementChild, page)) {
                showNonPrintableChars(editor, page);
            }
        }
        result = true;
    }
    if (caretPosition) {
        setCaretPosition(
            editor,
            caretPosition.contextElement,
            caretPosition.path,
        );
    }
    return result;
}

/**
 * @param editor
 * @param node {HTMLElement | Node}
 */
export function showNonPrintableChars(editor, node) {
    if (!node) return;
    showSpaces(editor, node);
    showBreakLines(editor, node);
}

/**
 * @param page {HTMLElement | Node}
 */
export function removeNonPrintableChars(page) {
    removeBreakLines(page);
    removeSpaces(page);
    removeInvisibleSpaces(page);
}

/**
 * @param node {HTMLElement}
 * @returns {boolean}
 */
export function isShowingNonPrintableChars(node) {
    return (
        (node &&
            !!node.querySelectorAll(
                'editor-element[type="representation-space"]',
            ).length) ||
        !!node.querySelectorAll('editor-element[type="representation-nbsp"]')
            .length ||
        !!node.querySelectorAll(
            'editor-element[type="representation-line-break"]',
        ).length
    );
}

export function createRepresentationSpace(editor) {
    const representation = editor.dom.create('editor-element', {
        type: 'representation-space',
    });
    representation.innerText = ZERO_WIDTH_SPACE_CHAR;
    return representation;
}

export function createRepresentationNbsp(editor) {
    const representation = editor.dom.create('editor-element', {
        type: 'representation-nbsp',
    });
    representation.innerText = ZERO_WIDTH_SPACE_CHAR;
    return representation;
}

export function createRepresentationLineBreak(editor) {
    const representation = editor.dom.create('editor-element', {
        type: 'representation-line-break',
    });
    representation.innerText = ZERO_WIDTH_NB_CHAR;
    return representation;
}

/**
 * @param editor
 * @param node {HTMLElement | DocumentFragment}
 */
function showBreakLines(editor, node) {
    const breaks = node.querySelectorAll('br');
    for (let br of breaks) {
        if (
            !br.closest('editor-braille-view') &&
            !isEditorElementImage(node) &&
            !isEditorElementImageLayout(node) &&
            !isInsideAlignmentCenter(node) &&
            !isInsideAlignmentRight(node)
        ) {
            const closestElementAlignment = getClosestElementAlignment(
                br.previousElementSibling,
            );
            if (closestElementAlignment) {
                // this is a visualization fix because alignment element is a block display and br is hidden
                // br still existing (hidden) to flag paragraph end
                closestElementAlignment.appendChild(
                    createRepresentationLineBreak(editor),
                );
                // editor element image is a block and should have no line break representation
            } else {
                if (br.style.display === 'none') continue;
                br.parentNode.insertBefore(
                    createRepresentationLineBreak(editor),
                    br,
                );
            }
        }
    }
}

/**
 * @param node {HTMLElement | DocumentFragment}
 */
function removeBreakLines(node) {
    if (!node) return;
    const representations = node.querySelectorAll(
        'editor-element[type="representation-line-break"]',
    );
    for (let representation of representations) {
        representation.remove();
    }
}

/**
 * @param editor
 * @param node {HTMLElement | Node}
 */
function showSpaces(editor, node) {
    if (
        node.nodeType === Node.ELEMENT_NODE ||
        node.nodeType === Node.DOCUMENT_FRAGMENT_NODE
    ) {
        if (!isEditorBrailleView(node)) {
            for (let child of [...node.childNodes]) {
                showSpaces(editor, child);
            }
        }
    } else if (node.nodeType === Node.TEXT_NODE) {
        const content = node.nodeValue
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
        const replacedContent = content
            .replace(/ /g, createRepresentationSpace(editor).outerHTML)
            .replace(/\u00A0/g, createRepresentationNbsp(editor).outerHTML);
        const fragment = document.createDocumentFragment();
        const container = document.createElement('div');
        container.innerHTML = replacedContent;
        while (container.firstChild) {
            fragment.appendChild(container.firstChild);
        }
        const parent = node.parentNode;
        parent.replaceChild(fragment, node);
    }
}

/**
 * @param node {HTMLElement | DocumentFragment}
 */
function removeSpaces(node) {
    if (!node) return;
    for (const child of [
        ...node.querySelectorAll('editor-element[type="representation-space"]'),
    ]) {
        child.replaceWith(document.createTextNode(' '));
    }
    for (const child of [
        ...node.querySelectorAll('editor-element[type="representation-nbsp"]'),
    ]) {
        child.replaceWith(document.createTextNode('\u00A0'));
    }
}

/**
 * @param node {HTMLElement | DocumentFragment | null}
 */
export function fixSpaceChain(node) {
    if (!node) return;
    const spaces = [];
    let editorElements = node.querySelectorAll(
        'editor-element[type="representation-space"]',
    );
    for (let space of editorElements) {
        spaces.push(space);
    }
    editorElements = node.querySelectorAll(
        'editor-element[type="representation-nbsp"]',
    );
    for (let nbsp of editorElements) {
        spaces.push(nbsp);
    }

    let fixed = false;
    while (spaces.length) {
        let walk = spaces.shift();
        let isSpace = isEditorElementRepresentationSpace(walk);
        while (
            walk &&
            (isEditorElementRepresentationSpace(walk) ||
                isEditorElementRepresentationNbsp(walk))
        ) {
            let type;
            if (isSpace) {
                type = 'representation-space';
            } else {
                type = 'representation-nbsp';
            }
            isSpace = !isSpace;
            fixed = walk.getAttribute('type') !== type;
            walk.setAttribute('type', type);
            let idxRemove = spaces.indexOf(walk);
            if (idxRemove !== -1) spaces.splice(idxRemove, 1);
            do {
                walk = walk.nextSibling;
            } while (walk && isInvisibleSpace(walk.textContent));
        }
    }
    return fixed;
}

/**
 * @param text {string}
 * @returns {string}
 */
export function removeInvisibleSpacesFromText(text) {
    return text
        .replaceAll(ZERO_WIDTH_NB_CHAR, '')
        .replaceAll(ZERO_WIDTH_SPACE_CHAR, '');
}

/**
 * @param node {HTMLElement | DocumentFragment}
 */
function removeInvisibleSpaces(node) {
    if (!node) return;
    recursively(node);

    function recursively(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            if (node.textContent === '') {
                node.remove();
            } else {
                node.textContent = removeInvisibleSpacesFromText(
                    node.textContent,
                );
                if (!node.textContent.length) {
                    if (
                        isInsideEditorElementImage(node) ||
                        isInsideEditorElementImageLayout(node) ||
                        isInsideAlignmentRight(node) ||
                        isInsideAlignmentCenter(node)
                    ) {
                        // if empty, focus stop to work (keep an invisible char when empty)
                        node.textContent = ZERO_WIDTH_NB_CHAR;
                    } else {
                        node.remove();
                    }
                }
            }
        } else {
            for (let child of node.childNodes) {
                recursively(child);
            }
        }
    }
}
